import React, { useEffect } from 'react';
import { Container, Grid, Box } from '@mui/material';
import {
  RichText,
  Image,
  Text,
  DateField
} from '@sitecore-jss/sitecore-jss-react';
import Heading from '../common/HeadingComponent';
import { dateOptions } from '../constants';
import './newsdetails.scss';
import { Helmet } from 'react-helmet';

const Newsdetails = (props) => {
  const { fields, context } = props;
  const {
    articleTitle = '',
    category = [],
    articleBottomDescription = '',
    articleDate,
    articleDateText,
    articleMiddleDescription,
    articleMiddleImage,
    articleShortDescription,
    articleTopDescription,
    articleTopImage
  } = fields;

  const lang = context.language;
  const [url, setUrl] = React.useState(false);
  React.useEffect(() => {
    setUrl(window?.location?.href);
  }, []);

  const NewsDetailsobject = `{"@context": "https://schema.org","@type": "NewsArticle","headline": "${articleTitle?.value}","image": "${articleTopImage?.value?.src}",
  "datePublished":"${articleDate?.value}","dateModified":"${articleDate?.value}","author": {"@type": "Corporation","name": "Allstate Insurance Canada",
  "Url": "${url}"}}`;

  const showCateory = (category) => {
    return category.map((item) => {
      const { id, fields } = item;
      const { categorylogo, categoryname } = fields;
      return (
        <span key={id} className="category-wrapper">
          <Image
            className="category-icon"
            field={categorylogo?.fields?.Image}
            height={40}
            width={40}
          />
          <Text field={categoryname} />
        </span>
      );
    });
  };
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{NewsDetailsobject}</script>
      </Helmet>
      <Container
        className="section-padding news-details-wrapper"
        role="region"
        aria-label="newsDetails">
        <Grid container>
          <Grid item md={12}>
            <Heading
              className="news-details-heading"
              alignText={'left'}
              heading={articleTitle}
            />
            {category.length > 0 && showCateory(category)}
            <Box className="update-date">
              <Text field={articleDateText} />
              <DateField
                className="card-date"
                field={articleDate}
                render={(date) => date.toLocaleString(lang, dateOptions)}
              />
            </Box>
          </Grid>
          <Grid item md={8} className="md-pr-20">
            <RichText field={articleTopDescription} />
          </Grid>
          <Grid item md={4}>
            <Image
              className="news-article-img hidden-mobile"
              field={articleTopImage}
            />
          </Grid>
          {articleMiddleDescription && (
            <RichText field={articleMiddleDescription} />
          )}
          {articleMiddleImage && (
            <Image
              className="news-banner hidden-mobile"
              field={articleMiddleImage}
            />
          )}
          {articleBottomDescription && (
            <RichText field={articleBottomDescription} />
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Newsdetails;
