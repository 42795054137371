import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import arrowmoreicon from '../../../assets/arrowmoreicon.svg';
import './ExploreCard.scss';
import HTagComponent from '../../common/HTagComponent/HTagComponent';

const ExploreCard = (props) => {
  const { CTAImage, CTAHeading, CTASummary, CTALink, CTAHeadingHTag } =
    props?.cardData?.fields;
  return (
    <Grid item xs={12} sm={4} md={4} lg={4} className="card-wrapper">
      {/* <Box sx={{ maxWidth: 340, maxHeight: 310 }} className="mainbox"> */}
      <Box sx={{ maxWidth: 358 }} className="mainbox">
        <Card sx={{ boxShadow: 7 }} className="exploreSectionCard">
          <CardContent>
            <Typography className="hidden-mobile imageIcon">
              <Image field={CTAImage} height={54} width={54} loading="lazy" />
            </Typography>
            <Typography className="cardTitle">
              {CTAHeadingHTag?.value !== '' && CTAHeadingHTag ? (
                <HTagComponent
                  alignText={'left'}
                  hTag={CTAHeadingHTag}
                  TitleText={CTAHeading}
                />
              ) : (
                <Text field={CTAHeading} loading="lazy" />
              )}
            </Typography>
            <Typography
              sx={{
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                // display: '-webkit-box',
                // WebkitLineClamp: '3',
                overflow: 'visible',
                WebkitBoxOrient: 'vertical',
                mb: 1.5
              }}
              className="cardDesc">
              <RichText field={CTASummary} />
            </Typography>
          </CardContent>
          {CTALink?.value?.text && (
            <CardActions>
              <Box className="btn-grp">
                <Button
                  href={CTALink?.value?.href}
                  target={CTALink?.value?.target}
                  // endIcon={
                  //   <img
                  //     src={arrowmoreicon}
                  //     alt="more"
                  //     loading="lazy"
                  //     className="cardActionicon"
                  //     height={9}
                  //     width={5}
                  //   />
                  // }
                  aria-label={CTALink?.value?.text}
                  className="cardActionLink">
                  {CTALink?.value?.text}
                </Button>
                <img
                  src={arrowmoreicon}
                  alt="more"
                  loading="lazy"
                  className="cardActionicon"
                  height={9}
                  width={5}
                />
              </Box>
            </CardActions>
          )}
        </Card>
      </Box>
    </Grid>
  );
};

export default ExploreCard;
