import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './heading.scss';

const Heading = ({
  caption = null,
  heading = null,
  description = null,
  components = {},
  className = '',
  alignText = 'center'
}) => {
  const { headingTag: htag = 'h2', captionTag: ctag = 'p' } = components; // change tags if required for seo optimization
  return (
    <Box className={`heading-wrapper ${className}`}>
      {caption?.value && (
        <Typography
          align={alignText}
          component={ctag}
          className={'heading-caption'}>
          <RichText field={caption} />
        </Typography>
      )}
      {heading?.value && (
        <Typography
          align={alignText}
          component={htag}
          className={'heading-title '}>
          <RichText field={heading} />
        </Typography>
      )}
      {description?.value && (
        <Typography
          align={alignText}
          component="p"
          className={'heading-description'}>
          <RichText field={description} />
        </Typography>
      )}
    </Box>
  );
};

export default Heading;
