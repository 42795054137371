import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import RichTextComponent from '../RichTextComponent';
import Heading from '../HeadingComponent';
import AllstateButton from '../Button';
import AllstateAccordion from '../Accordian';
import { withSearch } from '../../../contexts/search';
import SearchResultHeader from '../SearchResultHeader';
import './LinearLayout.scss';

const LinearLayout = (props) => {
  const {
    title = '',
    buttonDetails = null,
    content = '',
    classes = '',
    AccordionList = [],
    accordionTitle = null,
    isFAQ = false,
    searchKeyword, // Note: search context value
    hasError, // Note: search context value
    errorData // Note: search context value
  } = props;
  const showAccordion = AccordionList.length > 0;
  return (
    <Box
      className={`LinearLayout ${classes} section-padding`}
      role="region"
      aria-label="linearLayout">
      <Grid container md={12} lg={12} className="LinearLayoutGridContainer">
        <Grid item sm={12} md={4} lg={4}>
          <Grid container md={12} lg={12} className="rightContent">
            <Grid
              item
              sm={12}
              className={
                showAccordion || isFAQ ? 'hidden-mobile hidden-tablet' : ''
              }>
              <Heading
                caption={title}
                alignText="left"
                className="pageInfoTitle"
              />
            </Grid>
            {buttonDetails?.value?.text && (
              <Grid item sm={12} className="LinearLayoutBtnDesktop">
                <AllstateButton
                  content={buttonDetails}
                  size="medium"
                  className="list-col-layout-btn"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item sm={12} md={8} lg={8}>
          <Paper elevation={0} className="leftContent">
            {isFAQ && (hasError || !!searchKeyword.length) && (
              <SearchResultHeader />
            )}
            {(() => {
              if (isFAQ) {
                if (hasError) {
                  return (
                    <Box className="faqValidationPadding">
                      <RichTextComponent
                        content={errorData}
                        richTextClasses="faqValidationMsg"
                      />
                    </Box>
                  );
                } else {
                  return (
                    <AllstateAccordion
                      accordionTitle={!searchKeyword.length && accordionTitle}
                      accordianData={AccordionList}
                      setFirstIndexOpen={true}
                    />
                  );
                }
              } else if (showAccordion) {
                return (
                  <AllstateAccordion
                    accordionTitle={accordionTitle}
                    accordianData={AccordionList}
                    setFirstIndexOpen={true}
                  />
                );
              } else {
                return <RichTextComponent content={content} />;
              }
            })()}
          </Paper>
        </Grid>
        {buttonDetails && (
          <Grid item sm={12} className="LinearLayoutBtnGrid">
            {isFAQ && (
              <Grid
                item
                sm={12}
                className={showAccordion ? 'show-mobile heading-style' : ''}>
                <Heading
                  caption={title}
                  alignText="left"
                  className="pageInfoTitle"
                />
              </Grid>
            )}
            {buttonDetails?.value?.text && (
              <AllstateButton
                content={buttonDetails}
                size="medium"
                className={'LinearLayoutBtnMobile'}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

LinearLayout.defaultProps = {
  classes: ''
};

LinearLayout.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  exploreButton: PropTypes.object
};

export default withSearch(LinearLayout);
