import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const HTagComponent = ({ hTag, TitleText, alignText }) => {
  if (hTag?.value === 'H1') {
    return (
      <Typography align={alignText}>
        <h1 className="h1-class">
          <RichText field={TitleText} />
        </h1>
      </Typography>
    );
  } else if (hTag?.value === 'H2') {
    return (
      <Typography align={alignText}>
        <h2 className="h2-class">
          <RichText field={TitleText} />
        </h2>
      </Typography>
    );
  } else if (hTag?.value === 'H3') {
    return (
      <Typography align={alignText}>
        <h3 className="h3-class">
          <RichText field={TitleText} />
        </h3>
      </Typography>
    );
  } else if (hTag?.value === 'H4') {
    return (
      <Typography align={alignText}>
        <h4 className="h4-class">
          <RichText field={TitleText} />
        </h4>
      </Typography>
    );
  }
};

export default HTagComponent;
