import React from 'react';
import { Grid, Typography, Container, Box } from '@mui/material';
import AllstateButton from '../common/Button';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Heading from '../common/HeadingComponent';
import { useWindowSize } from '../../utils/useWindowSize';
import './bigbanner.scss';
import BulletPoint from '../common/BulletPoint/BulletPoint';
import HTagComponent from '../common/HTagComponent/HTagComponent';

const BigBanner = (props) => {
  const {
    BannerLabel,
    BannerHeading,
    BannerSummary,
    BottomLineText,
    TargetUrl,
    Image,
    Listitem
  } = props.fields;
  const [width] = useWindowSize();
  const alignText = width > 600 ? 'left' : 'center';
  return (
    <Box className="mobile-app-feature-wrapper">
      <Container>
        <Grid container className="mobile-app-feature-wrapper section-padding">
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            className={'image-container hidden-mobile hidden-tablet'}>
            <img
              src={Image?.value?.src}
              alt={Image?.value?.alt}
              className="image-content"
              loading="lazy"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className={'mobile-app-features'}
            role="region"
            aria-label="bulletPoint">
            <Heading
              alignText={alignText}
              caption={BannerLabel}
              className="bigbanner-caption"
              components={{
                headingTag: BannerLabel.value !== '' ? 'h4' : 'h2',
                captionTag: 'h3'
              }}
            />
            <div className="bigbanner-heading">
              <HTagComponent
                TitleText={BannerHeading}
                hTag={'h2'}
                alignText={'left'}
              />
            </div>

            <BulletPoint
              content={Listitem}
              bulletStyle={'Bullet-point-style'}
            />
            <Box className="Bottom-line-text">
              <RichText field={BottomLineText} />
            </Box>
            {TargetUrl?.value?.text && (
              <AllstateButton
                buttonTextclasses={'text-style'}
                className={'button-styles'}
                variant="primary"
                size="medium"
                content={TargetUrl}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BigBanner;
