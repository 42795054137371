import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import './LevelTwoRichTextCard.scss';

const LevelTwoRichTextCard = ({ data }) => {
  return (
    <div className="LevelTwoCard">
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        className="gridclass"
        justifyContent={'center'}
        alignItems={'center'}>
        <Box className="mainbox">
          <Card sx={{ boxShadow: 7 }} className="exploreSectionCard">
            <CardContent>
              <Typography className="imageIcon">
                <Image field={data?.CTAImage} loading="lazy" />
              </Typography>
              <Typography className="cardTitleLevel">
                <Text field={data?.CTAHeading} />
              </Typography>
              <Typography className="cardDescLevel">
                <RichText field={data?.CTASummary} />
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </div>
  );
};

export default LevelTwoRichTextCard;
