import React, { useState } from 'react';
import {
  Container,
  Grid,
  MenuItem,
  Typography,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import MenuListItem from './MenuListItem';
import TopNavStrip from './TopNavStrip';
import MobileNav from './MobileNavigation';
import Dropdownarrow from '../../assets/down-arrow.svg';
import './header.scss';

const Header = ({ fields, context }) => {
  const {
    logolink = null,
    primaryHeaderMenuLinks = [],
    logoImage = {},
    topHeaderMenuLinks = []
  } = fields || {};
  const [anchorEl, setAnchorEl] = useState(false);
  const [show, setShow] = useState('');
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const logosrc = logoImage?.value?.src;

  // const logoAllstate = process.env.NODE_ENV === 'development' ?
  //   `${process.env.REACT_APP_DEV_HOSTNAME_IMG_URL}${logosrc}` : logosrc;

  const renderDropdownitems = (menuSection) => {
    return menuSection?.map((menuItem, i) => {
      const { fields } = menuItem;
      const { submenuSection = null } = fields;
      const panelValue = `panel${i}`;
      const onOpen = (name) => {
        if (show === '') {
          setShow(name);
        } else if (show === name) {
          setShow('');
        } else {
          setShow(name);
        }
      };
      const bindLink = (fields) => {
        return (
          <Link
            tabIndex={0}
            className="menu-dropdown-list-links menulist-bold"
            field={fields.Link}></Link>
        );
      };
      return (
        <>
          {submenuSection?.length > 0 ? (
            <>
              <Button
                onClick={() => onOpen(fields?.menuText?.value)}
                className="menu-dropdown"
                endIcon={
                  submenuSection?.length > 0 ? (
                    <img
                      src={Dropdownarrow}
                      alt="account-icon"
                      loading="lazy"
                      className="arrow-icon"
                      height={5}
                      width={9}
                    />
                  ) : (
                    ''
                  )
                }>
                {fields?.menuText?.value}
              </Button>
              <>
                {submenuSection &&
                  renderSubmenulevel2(submenuSection, fields?.menuText?.value)}
              </>
            </>
          ) : (
            // <MenuItem className="menu-dropdown-list" key={panelValue}>
            //   <Accordion
            //     className="menu-accordion"
            //     expanded={expanded === panelValue}
            //     onChange={handleChange(panelValue)}>
            //     <AccordionSummary
            //       expandIcon={
            //         <img
            //           src={Dropdownarrow}
            //           alt="location-icon"
            //           loading="lazy"
            //         />
            //       }
            //       aria-controls="panel1d-content"
            //       id="panel1d-header">
            //       <Typography className="menu-dropdown">
            //         {fields?.menuText?.value}
            //       </Typography>
            //     </AccordionSummary>
            //     <AccordionDetails>
            //       {submenuSection && renderSubmenulevel2(submenuSection)}
            //     </AccordionDetails>
            //   </Accordion>
            // </MenuItem>
            <>
              {fields?.Link?.value?.href && (
                <MenuItem
                  className="link-wrapper"
                  key={panelValue}
                  id={fields?.Link?.value?.text}
                  component={() => bindLink(fields)}>
                  {/* <Link
                      className="menu-dropdown-list-links menulist-bold"
                      field={fields.Link}></Link> */}
                </MenuItem>
              )}
            </>
          )}
        </>
      );
    });
  };
  const renderSubmenulevel2 = (submenuData, name) => {
    const bindLink1 = (fields, name) => {
      return (
        <Link
          // className="menu-dropdown-list-links"
          className={
            name === show ? 'showDiv menu-dropdown-list-links' : 'hideDiv'
          }
          id={name}
          tabIndex={0}
          field={fields.Link}></Link>
      );
    };
    return submenuData?.map((submenuItem, i) => {
      const { fields } = submenuItem;
      const submenuValue = `submenu${i}`;

      return (
        <>
          {fields?.Link?.value?.href && (
            <MenuItem
              className="link-wrapper"
              name={name}
              key={submenuValue}
              id={fields?.Link?.value?.text}
              component={() => bindLink1(fields, name)}>
              {/* <Link
                field={fields.Link}
                className="menu-dropdown-list-links"></Link> */}
            </MenuItem>
          )}
        </>
      );
    });
  };

  const renderMenu = (primaryMenuLinks) => {
    return primaryMenuLinks?.map((primaryMenuLink, i) => {
      const { fields } = primaryMenuLink;
      const showDivider = !(primaryMenuLinks.length === i + 1);
      return (
        <>
          <MenuListItem
            primaryMenuLink={primaryMenuLink}
            showDivider={showDivider}
            renderDropdownitems={renderDropdownitems}
            tabIndex={1}
          />
        </>
      );
    });
  };
  return (
    <>
      <TopNavStrip
        topHeaderMenuLinks={topHeaderMenuLinks}
        language={context?.language}
        geoLocation={context?.region}
      />
      <MobileNav {...fields} {...context} />
      <Container fluid className="mtop-50-xs hidden-mobile ">
        <Grid container spacing={2} className={'padlr-0 padtb-12 mart-0'}>
          <Grid item xs={2} md={2} lg={4}>
            <span className="logo-container">
              <Link field={logolink?.value}>
                <img src={logosrc} alt="Allstate" loading="lazy" />
              </Link>
            </span>
          </Grid>
          <Grid
            item
            xs={10}
            md={10}
            lg={8}
            className={'menu'}
            role="region"
            aria-label="menu">
            {primaryHeaderMenuLinks?.length > 0 &&
              renderMenu(primaryHeaderMenuLinks)}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Header;
