import React, { useRef } from 'react';
import { Popper, ClickAwayListener } from '@mui/material';
import Close_Icon from '../../assets/remove_close.svg';
import AboutContent from './AboutContent';
import './aboutallstate.scss';

const AllStateAbout = (props) => {
  const { handleClick, anchorEl } = props;
  const imgRef = useRef(null);
  const open = Boolean(anchorEl);
  const id = open ? 'about-allstate-popup' : undefined;
  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      handleClick(null);
    }
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        imgRef?.current?.focus();
      }}>
      <Popper
        className="aboutallstate-popup"
        id={id}
        open={open}
        tabIndex={0}
        ref={imgRef}
        placement="bottom-start"
        anchorEl={anchorEl}>
        <img
          tabIndex={0}
          alt="close-icon"
          src={Close_Icon}
          className="close-popper"
          onKeyDown={handleKeyDown}
          onClick={() => handleClick(null)}
          id="close-icon"
          loading="lazy"
          height={13}
          width={13}
        />
        <AboutContent {...props} />
      </Popper>
    </ClickAwayListener>
  );
};

export default AllStateAbout;
