import React, { useState, useEffect } from 'react';
import { Container, Grid, Box } from '@mui/material';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../utils/useWindowSize';
import Heading from '../common/HeadingComponent';
import NewsCard from './NewsRoomCard';
import AllstateTabs from '../common/TabComponent';
import Pagination from '../common/Pagination';
import { getPaginationCount } from '../../utils';
import { useHttp } from '../../utils/useHttp';
import {
  newsRoomApiUrl as apiUrl,
  resultsPerPageNews as pageSize
} from '../constants';
import './newslisting.scss';

const Newslisting = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const [width] = useWindowSize();
  const [tabList, setTabList] = useState(null);
  const [tabValue, setTabVal] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageValue = searchParams?.get('page');
  const pageNumber = pageValue || 1;
  const { category = null, sectiontitle = {} } = props?.fields;
  const categoryQuery = tabValue?.fields?.categoryId;
  const categoryIcon = tabValue?.fields?.Image;
  const query = categoryQuery
    ? `?categoryId=${categoryQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    : undefined;
  const newsResults = useHttp({ apiUrl, query });
  const TotalResults = newsResults?.data
    ? newsResults?.data[0]?.TotalResults
    : 0;
  const count = getPaginationCount(TotalResults, pageSize);
  useEffect(() => {
    // Re-create object as per AllstateTabs component requirement.
    const _list =
      category &&
      category?.map((val) => {
        return {
          fields: {
            Image: val?.fields?.categorylogo?.fields?.Image,
            ImageCaption: val?.fields?.categoryname,
            categoryId: val?.id
          }
        };
      });
    setTabList(_list);
    setTabVal(_list[0]); // set initial tab value on pageload
  }, []);
  const onTabChange = (tabval) => {
    if (pageValue) {
      searchParams?.delete('page');
      setSearchParams(searchParams);
    }
    setTabVal(tabList[tabval]);
  };
  const fitTabtoScreen = tabList?.length < 4;
  const tabVariant = width > 600 || fitTabtoScreen ? 'fullWidth' : 'scrollable';
  const renderNewsData = (data, icon) => {
    return data?.map((newsItem, i) => {
      return (
        <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
          <NewsCard icon={icon} cardDetails={newsItem} />
        </Grid>
      );
    });
  };
  return (
    <Box className="newsroom-container" role="region" aria-label="newlisting">
      <Container className="section-padding">
        <Heading heading={sectiontitle} />
        {tabList && (
          <AllstateTabs
            fitTabtoScreen={fitTabtoScreen}
            tabVariant={tabVariant}
            handleChangeCallback={onTabChange}
            tabCategories={tabList}
          />
        )}
        {newsResults?.data?.length > 0 && (
          <>
            <Grid container className="card-wrapper">
              {renderNewsData(newsResults?.data[0]?.Results, categoryIcon)}
            </Grid>
            {TotalResults > 5 && (
              <Box className="pagination-wrapper">
                <Pagination
                  resultsPerPage={pageSize}
                  count={count}
                  page={Number(pageNumber)}
                  pathname={pathname}
                  TotalResults={TotalResults}
                />
              </Box>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default Newslisting;
