import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Container, Grid, Box, Button } from '@mui/material';
import '../JssForm/JssForm.scss';

const FormBlueBanner = ({ fields }) => {
  return (
    <Container>
      <Grid container className="">
        <Grid item sm={12} md={3} lg={3}></Grid>
        <Grid item sm={12} md={9} lg={9}>
          <Box className="form-blue-banner">
            <RichText field={fields?.Text} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FormBlueBanner;
