import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Image, Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import { Box } from '@mui/material';
import './CardLayout.scss';

export default function CardLayout({ data }) {
  return (
    <Card className="card-layout">
      <Box>
        <Image field={data?.Image} loading="lazy" />
      </Box>
      <CardContent>
        <Box
          className="img-title"
          sx={{
            WebkitBoxOrient: 'vertical'
          }}>
          <Text field={data?.Title} />
        </Box>
        <Box
          className="card-description"
          sx={{
            overflow: 'visible',
            WebkitBoxOrient: 'vertical'
          }}>
          <Text field={data?.Description} />
        </Box>
      </CardContent>
      <CardActions>
        <Box className="card-button">
          <Button
            href={data?.CardUrl?.value?.href}
            target={data?.CardUrl?.value?.target}
            tabIndex={0}
            className="button-link">
            {data?.CardUrl?.value?.text}
          </Button>
          <i className="arrow right"></i>
        </Box>
      </CardActions>
    </Card>
  );
}
