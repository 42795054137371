import React from 'react';
import './SubFooterComponent.scss';
import { Container, Grid, Box } from '@mui/material';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import ScrollToTopButton from './ScrollToTopButton';

function LinkIcons(socialFooterLinks) {
  return (
    <div className="SocialIcons">
      {socialFooterLinks.map(
        (link, index) =>
          link?.fields?.Image?.value?.src && (
            <a
              key={index}
              href={link?.fields?.TargetUrl?.value?.href}
              target={link?.fields?.TargetUrl?.value?.target}>
              <Image
                className="ImageIcon"
                field={link?.fields?.Image}
                loading="lazy"
                height={19}
                width={19}
              />
            </a>
          )
      )}
    </div>
  );
}

const SubFooterComponent = ({
  copyrightFooterLink,
  socialFooterLinks,
  topButtonText
}) => {
  const renderListLinks = (copyrightFooterLink) => {
    return (
      <ul className="SubFooterLinksList">
        {copyrightFooterLink.map((item, i) => {
          return (
            <li className="Allcontent" key={i}>
              {
                <div className="subfootercontent">
                  {item?.fields?.copyRightTitle ? (
                    <RichText field={item?.fields?.copyRightTitle} />
                  ) : (
                    <a
                      href={item?.fields?.Link?.value?.href}
                      target={item?.fields?.Link?.value?.target}
                      className="Subfooterlinks">
                      {item?.fields?.Link?.value?.text}
                    </a>
                  )}
                </div>
              }
            </li>
          );
        })}
        <li>{LinkIcons(socialFooterLinks)}</li>
      </ul>
    );
  };
  return (
    <Container className="padzero">
      <Grid
        className="LinksandIcons"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}>
        <div className="subfootercontentbar">
          {renderListLinks(copyrightFooterLink)}
        </div>
      </Grid>
      <Box className="ScrollToTopButtonSection">
        <ScrollToTopButton text={topButtonText?.value} />
      </Box>
    </Container>
  );
};
export default SubFooterComponent;
