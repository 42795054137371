import React from 'react';
import { Popper, Box, Paper, Grid } from '@mui/material';
import { Text, RichText, Link, Image } from '@sitecore-jss/sitecore-jss-react';
import AllstateButton from '../common/Button';
import './aboutallstate.scss';

const AboutContent = (props) => {
  const {
    Description,
    MobileAppSection,
    MobileAppTitle,
    SocialShareSection,
    SocialShareTitle,
    LinkSection,
    Title
  } = props?.fields;
  const renderSocialSections = (title, links) => {
    return (
      <>
        <Text tag="p" className="social-heading" field={title} />{' '}
        <ul className="pdl-0">{renderMobileLinks(links)}</ul>
      </>
    );
  };
  const renderList = (listItems) => {
    return listItems?.map((listItem, i) => {
      const link = listItem?.fields?.Link;
      return (
        <li className="links-li-text" key={i}>
          <Link field={link} tabIndex={0} />
        </li>
      );
    });
  };
  const renderLinks = (links) => {
    const columns = links?.filter((item) => {
      return item?.fields?.Title;
    });
    return columns?.map((list, i) => {
      const title = list?.fields?.Title;
      const startIndex = links.findIndex((item) => {
        return list?.fields?.Title === item?.fields?.Title;
      });
      let endIndex = links.findIndex((item, i) => {
        if (item?.fields?.Title && i > startIndex) {
          return list?.fields?.Title !== item?.fields?.Title;
        }
      });
      endIndex = endIndex !== -1 ? endIndex : links.length;
      return (
        <Grid className="link-columns" item xs={6} key={i}>
          {title && (
            <h6 className="links-heading">
              <Text field={title} />
            </h6>
          )}
          {
            <ul className="pdl-0">
              {renderList(links?.slice(startIndex + 1, endIndex))}
            </ul>
          }
        </Grid>
      );
    });
  };

  const renderMobileLinks = (links) => {
    return links?.map((link, i) => {
      const items = link?.fields;
      const { TargetUrl } = items;
      return (
        <li className="list-items-social" key={i}>
          <Link field={TargetUrl} tabIndex={0}>
            <Image
              field={items?.Image}
              loading="lazy"
              height={items?.Image?.value?.height}
              width={items?.Image?.value?.width}
            />
          </Link>
        </li>
      );
    });
  };
  return (
    <Paper className="about-section-wrapper">
      <Box className="box-wrapper pad-20">
        <h6 className="popper-title">
          <Text field={Title} />
        </h6>
        <p className="popper-desc">
          <Text field={Description} />
        </p>
      </Box>
      <Grid xs={12} className="link-items pad-20">
        {renderLinks(LinkSection)}
      </Grid>
      <Box className="pad-lr20 mar-b20">
        <AllstateButton
          size="small"
          content={props?.fields?.Link}
          variant="search"
          className="popper-button"
        />
      </Box>
      <Grid xs={12} className="social-items pad-lr20" sx={{ p: 1 }}>
        <Grid md={8} xs={12} className="mobile-app">
          {renderSocialSections(MobileAppTitle, MobileAppSection)}
        </Grid>
        <Grid md={4} xs={12} className="social-app">
          {renderSocialSections(SocialShareTitle, SocialShareSection)}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AboutContent;
