import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Heading from '../HeadingComponent';
import AllstateButton from '../Button';
import { withSearch } from '../../../contexts/search';
import SearchResultHeader from '../SearchResultHeader';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import './ListCollectionLayout.scss';
import RightBulleticon from '../../../assets/RightBulleticon.svg';
import { useWindowSize } from '../../../utils/useWindowSize';
import BulletPoint from '../BulletPoint/BulletPoint';
import { handleAnchorClick } from '../../../utils';
import HTagComponent from '../HTagComponent/HTagComponent';

const ListCollectionLayout = (props) => {
  const {
    lefttitle = '',
    leftbuttonDetails = null,
    Title = '',
    ListImage = '',
    Description = '',
    content = [],
    BottomListText = '',
    classes = '',
    ListImageMobile = '',
    LeftColTextHTag,
    TitleHTag
  } = props;
  const [width] = useWindowSize();

  useEffect(() => {
    const anchorval = document.getElementsByClassName('sectionanchor');
    const anchorvalArr = Array.prototype.slice.call(anchorval);
    anchorvalArr?.map((anchorElement) => {
      anchorElement?.addEventListener('click', handleAnchorClick);
    });
    return () => {
      anchorvalArr?.map((anchorElement) => {
        anchorElement?.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);

  const imageFunction = (width, dImage, mImage) => {
    if (width > 900 && dImage?.value?.src) {
      return (
        <Box className="Top-Image">
          <Image className="Image-Size" field={dImage} loading="lazy" />
        </Box>
      );
    } else if (width < 900 && mImage?.value?.src) {
      return (
        <Box className="Top-Image">
          <Image className="Image-Size" field={mImage} loading="lazy" />
        </Box>
      );
    } else if (width < 900 && mImage?.value && dImage?.value?.src) {
      return (
        <Box className="Top-Image">
          <Image className="Image-Size" field={dImage} loading="lazy" />
        </Box>
      );
    }
  };

  return (
    <Box className="LinearLayouts">
      <Grid
        container
        className="LinearLayoutGridContainer"
        role="region"
        aria-label="listcollectionLayout">
        <Grid item sm={12} md={3} lg={3}>
          <Grid container className="rightContents">
            <Grid item sm={12}>
              {LeftColTextHTag?.value !== '' && LeftColTextHTag ? (
                <div className="pageInfoTitles">
                  <HTagComponent TitleText={lefttitle} hTag={LeftColTextHTag} />
                </div>
              ) : (
                <Heading
                  caption={lefttitle}
                  alignText="left"
                  className="pageInfoTitles"
                />
              )}
            </Grid>
            {leftbuttonDetails?.value?.href && (
              <Grid item sm={12} className="LinearLayoutBtnDesktops">
                <AllstateButton
                  content={leftbuttonDetails}
                  size="medium"
                  className="layout-btn-desktop"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid className="RightGridContainer" item sm={12} md={9} lg={9}>
          {TitleHTag?.value !== '' && TitleHTag ? (
            <>
              {Title?.value && (
                <Box className="RightTitle">
                  <HTagComponent TitleText={Title} hTag={TitleHTag} />
                </Box>
              )}
            </>
          ) : (
            <>
              {Title?.value && (
                <Box className="RightTitle">
                  <RichText field={Title} />
                </Box>
              )}
            </>
          )}

          {/* Mobile image required with condition */}
          {/* {ListImage?.value?.src && (
            <Box className="Top-Image">
              <Image className="Image-Size" field={ListImage} loading="lazy" />
            </Box>
          )} */}
          {imageFunction(width, ListImage, ListImageMobile)}
          {Description?.value && (
            <Box className="TopDescription">
              <RichText field={Description} />
            </Box>
          )}
          {/* {content?.length > 0 && BulletPoint(content)} */}
          {content?.length > 0 && (
            <Box className="BottomBorder">
              <BulletPoint content={content} />
            </Box>
          )}
          {BottomListText?.value && (
            <Box className="BottomText">
              <RichText field={BottomListText} />
            </Box>
          )}
        </Grid>
        {width < 900 && leftbuttonDetails?.value?.href && (
          <Grid item sm={12} className="mobile-button-section">
            <AllstateButton
              content={leftbuttonDetails}
              className="mobile-button"
              size="medium"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

ListCollectionLayout.defaultProps = {
  classes: ''
};

ListCollectionLayout.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.object.isRequired,
  content: PropTypes.array,
  exploreButton: PropTypes.object
};

export default withSearch(ListCollectionLayout);
