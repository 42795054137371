import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { Grid, Typography, Container, Box } from '@mui/material';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './Error404notfound.scss';

const Error404notfound = ({ fields }) => {
  return (
    <Box className="error-page-container">
      <Container>
        <Grid container className="error-page-container">
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            className={'image-container hidden-mobile hidden-tablet'}>
            <Image
              field={fields?.ErrorImage}
              alt="Allstate"
              className=""
              loading="lazy"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="error-text-container">
            <Box className="bigbanner-heading">
              <Text field={fields?.ErrorTitle} />
            </Box>
            <Box className="heading-Bottom-line-text">
              <Image
                field={fields?.ErrorIcon?.fields?.Image}
                alt="Allstate"
                className="error-icon"
              />
              <Text field={fields?.ErrorDescription} />
            </Box>
            <Box className="heading-captions">
              <RichText field={fields?.ErrorShortDescrtiption} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Error404notfound;
