import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './tabs.scss';

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
};

const AllstateTabs = (props) => {
  const {
    tabCategories = null,
    tabVariant = 'fullWidth',
    handleChangeCallback = () => {},
    tabButtona11yProps = a11yProps,
    tabButtonClassName = '',
    tabClassName = '',
    tabProps,
    tabButtonProps,
    fitTabtoScreen,
    scrollButtons = true,
    ImageCaptionHTag,
    ...rest
  } = props;
  const [value, setValue] = React.useState(0);
  const [hoverImageIndex, setHoverImageIndex] = React.useState(null);
  const [isHovered, setIsHovered] = React.useState(false);
  const handleChange = (event, tabValue) => {
    setValue(tabValue);
    handleChangeCallback(tabValue); // use this callback to get values to parent if required
  };
  const changeHoverImage = (index) => {
    setHoverImageIndex(index);
  };
  const reduceWidth = tabCategories.length === 2 ? 'width-50' : '';

  const renderTabButtons = (props) => {
    return props?.tabCategories?.map((tab, index) => {
      const { Image: iconImage, ImageCaption } = tab.fields;
      const HoverImage = tab.fields?.HoverImage;
      return (
        <Tab
          aria-label={ImageCaption.value}
          tabIndex={0}
          className={`tab-button ${tabButtonClassName}`}
          key={`tab${index}`}
          icon={
            index === hoverImageIndex && isHovered ? (
              <Image
                field={HoverImage?.value?.src ? HoverImage : iconImage}
                loading="lazy"
                height={69}
                width={64}
              />
            ) : (
              <Image field={iconImage} loading="lazy" height={69} width={64} />
            )
          }
          label={ImageCaption?.value}
          component={tab.fields?.ImageCaptionHTag?.value}
          onMouseEnter={() => {
            changeHoverImage(index);
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            changeHoverImage(index);
            setIsHovered(false);
          }}
          {...tabButtona11yProps(index)}
          {...tabButtonProps}
        />
      );
    });
  };
  return (
    <Tabs
      {...rest}
      aria-label={value}
      value={value}
      onChange={handleChange}
      variant={tabVariant}
      scrollButtons
      allowScrollButtonsMobile
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.4 }
        }
      }}
      className={
        !fitTabtoScreen
          ? `tabs ${reduceWidth} ${tabClassName}`
          : `tabs ${reduceWidth} fit-tab-content ${tabClassName}`
      }>
      {tabCategories && renderTabButtons(props)}
    </Tabs>
  );
};

export default AllstateTabs;
