import React from 'react';
import { Paper, Grid, Box } from '@mui/material';
import { Image, Text, DateField, Link } from '@sitecore-jss/sitecore-jss-react';
import { dateOptions } from '../../constants';
import './newsroomcard.scss';

const NewsCard = (props) => {
  const { cardDetails, icon } = props;
  const {
    Article_Date,
    Article_Image,
    Article_Page_Title,
    Article_Short_Description,
    ItemPath,
    Category_Images
  } = cardDetails;
  const lang = sessionStorage.getItem('selectLang');
  const url = ItemPath?.split('/home')[1]; // to split full url from sitecore
  const cardIcon = Category_Images && Category_Images[0];

  return (
    <Grid item lg={4} xl={4} md={4} sm={1} className="news-item-wrapper">
      <Box>
        <Paper className="news-card-paper">
          <Box className="card-image">
            <img
              src={Article_Image}
              alt="article-image"
              className="image"
              loading="lazy"
            />
          </Box>
          <div className="card-details">
            <div className="info-wrapper">
              <DateField
                className="card-date"
                field={{ value: Article_Date }}
                render={(date) => date.toLocaleString(lang, dateOptions)}
              />
              <img
                className="card-icon"
                src={cardIcon}
                alt="card-icon"
                loading="lazy"
                height={30}
                width={30}
              />
            </div>
            <p className="article-title">
              <a className="link-reset" href={`${url}`}>
                {Article_Short_Description}{' '}
              </a>
            </p>
          </div>
        </Paper>
      </Box>
    </Grid>
  );
};

export default NewsCard;
