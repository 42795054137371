import React, { useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import expandicon from '../../assets/expandIcon.svg';
import AllstateAccordion from '../common/Accordian';
import './PolicyContainer.scss';
import AllstateButton from '../common/Button';
import Heading from '../common/HeadingComponent';
import { useWindowSize } from '../../utils/useWindowSize';
import { Container, Box } from '@mui/material';
import HTagComponent from '../common/HTagComponent/HTagComponent';

const PolicyContainer = ({ fields }) => {
  const [width] = useWindowSize();
  return (
    <Box>
      <Container
        fluid
        className={
          fields?.VariantFlag?.value === 'Policy'
            ? 'PolicyFeatureContainer'
            : 'FAQContainer'
        }
        role="region"
        aria-label="policyfaqcomp">
        {width > 600 && fields?.ExploreSectionLabel?.value && (
          <Box className="FAQLabel">
            <Text field={fields?.ExploreSectionLabel} />
          </Box>
        )}

        {width < 600 && fields?.VariantFlag?.value === 'FAQ' ? (
          <Box className="PolicyFeaturesHeadingSection">
            <div className="PolicyFeaturesHeading">
              {fields?.ExploreSectionHeadingHTag?.value !== '' &&
              fields?.ExploreSectionHeadingHTag ? (
                <HTagComponent
                  hTag={fields?.ExploreSectionHeadingHTag}
                  TitleText={fields?.VariantFlag}
                  alignText={'left'}
                />
              ) : (
                <Heading alignText={'Left'} heading={fields?.VariantFlag} />
              )}
            </div>
          </Box>
        ) : (
          <>
            {fields?.ExploreSectionHeading?.value && (
              <Box className="PolicyFeaturesHeadingSection">
                <div className="PolicyFeaturesHeading">
                  {fields?.ExploreSectionHeadingHTag?.value !== '' &&
                  fields?.ExploreSectionHeadingHTag ? (
                    <HTagComponent
                      hTag={fields?.ExploreSectionHeadingHTag}
                      TitleText={fields?.ExploreSectionHeading}
                      alignText={fields?.TextAlignment?.value}
                    />
                  ) : (
                    <Heading
                      alignText={fields?.TextAlignment?.value}
                      heading={fields?.ExploreSectionHeading}
                    />
                  )}
                </div>
              </Box>
            )}
          </>
        )}

        <div
          className={
            fields?.VariantFlag?.value === 'FAQ' ? 'FAQAccordion' : ''
          }>
          <AllstateAccordion
            accordianData={fields?.ExploreCategories}
            expandIcon={expandicon}
          />
        </div>

        {fields?.VariantFlag?.value === 'FAQ' && width < 600 && (
          <div className="FAQHeadingWithWrapper">
            <Heading
              alignText={'Left'}
              heading={fields?.ExploreSectionHeading}
            />
          </div>
        )}

        {fields?.ExploreAllButton?.value?.href && (
          <div className="FAQButtonSection">
            <AllstateButton
              size="medium"
              className="FAQButton"
              content={fields?.ExploreAllButton}
            />
          </div>
        )}
      </Container>
    </Box>
  );
};

export default PolicyContainer;
