import React, { useEffect, useState } from 'react';
import {
  Placeholder,
  VisitorIdentification
} from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
import { Container, Grid, Paper } from '@mui/material';
import { SearchProvider } from './contexts/search';
import { AlertProvider } from './contexts/alert';
import './styles/index.scss';

const Layout = (props) => {
  const { route, context } = props;
  useEffect(() => {
    // Perform sessionStorage action
    // sessionStorage.setItem('selectLang', route?.itemLanguage);
    // construct digitalData for analytics
    window.digitalData = {
      page: {
        id: context?.analytics?.page?.id || '',
        channel: context?.analytics?.page?.channel || ''
      }
    };
  }, []);
  useEffect(() => {
    // Perform sessionStorage action
    if (
      sessionStorage.getItem('selectLang') === null &&
      context?.region === 'QC'
    ) {
      // document.cookie = `allstateca#lang=${value};path=/`;
      sessionStorage.setItem('selectLang', 'fr-ca');
      // window.open(window.location.href, '_self');
      const engPresent = window.location.href?.includes('en-ca');
      if (engPresent) {
        const newURL = window.location.href?.replace('en-ca', 'fr-ca');
        window.open(`${newURL}`, '_self');
      } else {
        const newURL = window.location.href?.replace(
          window.location.origin,
          ''
        );
        const value = 'fr-ca';
        window.open(`${window.location.origin}/${value}${newURL}`, '_self');
      }
    } else {
      sessionStorage.setItem('selectLang', route?.itemLanguage);
    }
  }, []);

  useEffect(() => {
    if (window !== undefined) {
      setTimeout(() => {
        console.log('>>>>>>>DRC_pageload', window?._satellite?.track);
        window?._satellite?.track('DRC_pageload');
      }, [3000]);
    }
  }, []);

  // const HomePageBreadcrum = `{"@context": "https://schema.org","@type": "BreadcrumbList","@id":"${
  //   context?.ContextExtensions?.metaData?.fullCanonicalLink
  // }${'/#breadcrumb'}","itemListElement": [{"type": "ListItem",
  // "position": "0","name": "${route?.name}","item": "${
  //   context?.ContextExtensions?.metaData?.fullCanonicalLink
  // }"}`;
  const [isTabFocused, setIsTabFocused] = useState(true);
  const handlefocus = () => {
    setIsTabFocused(false);
  };
  const handleInvisible = () => {
    setIsTabFocused(true);
  };

  return (
    <React.Fragment>
      <Helmet>
        <noscript>{context?.analytics?.gtmNoScript}</noscript>
      </Helmet>
      <a
        href="#main"
        onFocus={handlefocus}
        onBlur={handleInvisible}
        className={
          isTabFocused
            ? 'skip-to-main-content-link'
            : 'skip-to-main-content-link-tabkey'
        }>
        Skip to main content
      </a>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        {context?.analytics?.excludeSiteFromSearch === '1' && (
          <meta name="robots" content="noindex" />
        )}
        {context?.analytics?.excludeSiteFromSearch === '1' && (
          <meta name="robots" content="nofollow" />
        )}
        {context?.analytics?.excludeSiteFromSearch !== '1' &&
          route?.fields?.NoFollow?.value === true && (
            <meta name="robots" content="noindex" />
          )}
        {context?.analytics?.excludeSiteFromSearch !== '1' &&
          route?.fields?.NoIndex?.value === true && (
            <meta name="robots" content="nofollow" />
          )}
        {'<!-- Google Tag Manager -->'}
        <script type="text/javascript">{context?.analytics?.gtmScript}</script>
        {'<!-- End Google Tag Manager -->'}
        {'<!-- Clarity Software -->'}
        <script type="text/javascript">
          {context?.analytics?.claritySoftwareUrl}
        </script>
        {'<!-- End Clarity Software -->'}
        <meta name="title" content={route?.fields?.Title?.value} />
        <meta name="Keyword" content={route?.fields?.MetaKeywords?.value} />
        <meta
          name="Description"
          content={route?.fields?.MetaDescription?.value}
        />
        <link
          rel="canonical"
          href={context?.ContextExtensions?.metaData?.fullCanonicalLink}
        />
        {context?.ContextExtensions?.hrefLanguageList?.map((item) => (
          // eslint-disable-next-line react/jsx-key
          <link
            rel="alternate"
            href={item?.langUrl}
            hrefLang={item?.langCode}
          />
        ))}
        {/* react-helmet twitterTags */}
        <meta
          name="twitter:card"
          content={route?.fields?.TwitterCardType?.value}
        />
        <meta name="twitter:site" content={route?.fields?.TwitterSite?.value} />
        <meta
          name="twitter:title"
          content={route?.fields?.TwitterTitle?.value}
        />
        <meta
          name="twitter:description"
          content={route?.fields?.TwitterDescription?.value}
        />
        <meta
          name="twitter:image"
          content={route?.fields?.TwitterImage?.value?.src}
        />
        {/* react-helmet OpenGraph Tags */}
        <meta
          property="og:title"
          content={route?.fields?.OpenGraphTitle?.value}
        />
        <meta
          property="og:type"
          content={route?.fields?.OpenGraphType?.value}
        />
        <meta
          property="og:url"
          content={context?.ContextExtensions?.metaData?.fullCanonicalLink}
        />
        <meta
          property="og:site_name"
          content={route?.fields?.OpenGraphSiteName?.value}
        />
        <meta
          property="og:description"
          content={route?.fields?.OpenGraphDescription?.value}
        />
        {/* react-helmet fb Tags */}
        <meta property="fb:app_id" content="" />
        <title>{route?.fields?.Title?.value || 'Page'}</title>
        <script src={context?.analytics?.adobeUrl} async></script>
        <script type="application/ld+json">
          {context?.analytics?.organizationMarkup}
        </script>
        <script type="application/ld+json">
          {context?.analytics?.searchMarkup}
        </script>
        {/* {(route?.name === 'Home' || route?.name === '404 not found') && (
          <script type="application/ld+json">{HomePageBreadcrum}</script>
        )} */}
      </Helmet>
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      {/* root placeholder for the app, which we add components to using route data */}
      <AlertProvider>
        <div className="pad-0" data-test-id="main-placeholder">
          <Paper
            className="header-fixed"
            id="navigation-container"
            elevation={1}>
            <Placeholder
              name="jss-header"
              rendering={route}
              context={context}
            />
          </Paper>
          <Container id="main" className="root-container" fluid disableGutters>
            <Grid>
              <SearchProvider>
                <Placeholder
                  name="jss-main"
                  rendering={route}
                  context={context}
                  H1Tag={route?.fields?.H1Title?.value}
                />
              </SearchProvider>
            </Grid>
          </Container>
          <Placeholder name="jss-footer" rendering={route} context={context} />
        </div>
      </AlertProvider>
    </React.Fragment>
  );
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
