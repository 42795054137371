import React from 'react';
import { AlertContext } from './alertContext';

const withAlert = (Child) => {
  const MyAlertComp = (props) => {
    return (
      <AlertContext.Consumer>
        {(context) => <Child {...props} {...context} />}
      </AlertContext.Consumer>
    );
  };
  MyAlertComp.displayName = 'MyAlertComp';
  return MyAlertComp;
};

export default withAlert;
