import React from 'react';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { Box, Grid, createTheme, Container } from '@mui/material';
import './SwipeImage.scss';

const ImageSwipeComponent = (props) => {
  const {
    Title = '',
    SwipeImage = '',
    Description = '',
    ImageOnLeft = ''
  } = props;
  return (
    <Box>
      <Container>
        <Grid
          item
          className="LeftRightJPGContainer"
          container
          spacing={1}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            flexDirection: ImageOnLeft?.value ? 'row' : 'row-reverse'
          }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="grid-column-left">
            <Image className="Images-size" field={SwipeImage} loading="lazy" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="grid-column-right">
            <Box className="title-text">
              <Text field={Title} />
            </Box>
            <Box>
              <RichText field={Description} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ImageSwipeComponent;
