import React from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './H1TagComponent.scss';

const H1TagComponent = (props) => {
  return (
    <Box className="PageTopSection">
      <Container className="pagetopSection-container">
        {props?.fields?.H1Title && (
          <div className="headingDiv-H1">
            <h1 className="heading-H1">
              <RichText field={props?.fields?.H1Title} />
            </h1>
          </div>
        )}
      </Container>
    </Box>
  );
};

export default H1TagComponent;
