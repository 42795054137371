import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { enableDebug } from '@sitecore-jss/sitecore-jss-react';
import AppRoot from './AppRoot';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from './temp/config';
import i18ninit from './i18n';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.REACT_APP_DEBUG) {
  enableDebug(process.env.REACT_APP_DEBUG);
}

/* eslint-disable no-underscore-dangle */

let renderFunction = (rootElement, component) => {
  const root = createRoot(rootElement);
  root.render(component);
};

let initLanguage =
  sessionStorage.getItem('selectLang') !== null
    ? sessionStorage.getItem('selectLang')
    : config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.
  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.
  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
  renderFunction = hydrateRoot;

  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState =
  __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE
    ? __JSS_STATE__.APOLLO_STATE
    : null;

const graphQLClient = GraphQLClientFactory(
  config.graphQLEndpoint,
  false,
  initialGraphQLState
);

datadogRum.init({
  applicationId: '1ff6462c-e53d-481a-9b86-766dcedf2e0e',
  clientToken: 'pub3476a9a7ce159a42b535b46d53c40696',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: '04180000219x---sitecore-xm-with-managed-cloud',
  env: 'PRD',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});
datadogRum.addRumGlobalContext('barometerit', '04180000219X');
datadogRum.addRumGlobalContext('envlevel', 'PRD');
datadogRum.startSessionReplayRecording();

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
i18ninit(initLanguage).then((langData) => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root');

  renderFunction(
    rootElement,
    <AppRoot
      langData={langData}
      path={window.location.pathname}
      Router={BrowserRouter}
      graphQLClient={graphQLClient}
      ssrState={__JSS_STATE__}
    />
  );
});
