import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Container from '@mui/material/Container';
import './ColumnSplitter.scss';

const Columnsplitter = ({ rendering, params }) => {
  const columns = params.EnabledPlaceholders?.split(',');
  return (
    <Container className="ColumnsplitterLayout ">
      {columns?.map((value) => {
        return (
          <div
            key={value}
            className={`jss-col ${params[`ColumnWidth${value}`]}`}>
            <Placeholder name={`jss-column-${value}`} rendering={rendering} />
          </div>
        );
      })}
    </Container>
  );
};

export default Columnsplitter;
