import React from 'react';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { Box, Grid, createTheme, Container } from '@mui/material';
import './SlimBanner.scss';
import AllstateButton from '../common/Button';

const SlimBanner = ({ fields }) => {
  return (
    <Box className="SlimBanner-Section">
      <Container>
        <Grid
          item
          className="SlimBanner-Container"
          container
          spacing={0}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          role="region"
          aria-label="slimbanner">
          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            <div className="Image-Text-Section">
              <div className="SlimBanner-Img">
                <Image
                  field={fields?.Icon[0]?.fields?.Image}
                  loading="lazy"
                  height={51}
                  width={51}
                />
              </div>
              <span className="GetLifeBack-BundleYourHome-Section">
                <div className="TitleText">
                  <Text field={fields?.Title} />
                </div>
                <div className="DescriptionText">
                  <Text field={fields?.Description} />
                </div>
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <div>
              <div className="SlimBanner-Button">
                <AllstateButton
                  size="medium"
                  className="SlimBanner-Button-Style"
                  buttonTextclasses="SlimBanner-Button-Style-text"
                  content={fields?.Link}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SlimBanner;
