import React from 'react';
import { Container, Box } from '@mui/material';
import LinearLayout from '../common/LinearLayout';
import './RichText.scss';

const RichText = ({ fields }) => (
  <Container>
    <Box className="richtext-section">
      <LinearLayout
        title={fields?.Title}
        buttonDetails={fields?.Link}
        content={fields?.Text}
      />
    </Box>
  </Container>
);
export default RichText;
