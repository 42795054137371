import { dictionaryServiceFactory } from '../lib/dictionary-service-factory';

const getDictionaryData = (context) => {
  // instantiate the dictionary service.
  const dictionaryServiceInstance = dictionaryServiceFactory.create();
  return dictionaryServiceInstance
    .fetchDictionaryData(context.language)
    .then((phrases) => {
      return phrases;
    })
    .catch((err) => {
      return null;
    });
};

export default getDictionaryData;
