import React, { useState } from 'react';
import {
  Image,
  Text,
  DateField,
  RichText
} from '@sitecore-jss/sitecore-jss-react';
import { Box, Container, Grid } from '@mui/material';
import './Testimony.scss';
import Typography from '@mui/material/Typography';
import { useWindowSize } from '../../utils/useWindowSize';

const HeroBanner = ({
  BackgroundImage,
  Heading,
  ForegroundImage,
  BannerFadingEffect
}) => {
  const [width] = useWindowSize();
  return (
    <Box
      sx={
        width > 600 && {
          backgroundImage: `url(${BackgroundImage?.value?.src})`,
          backgroundSize: 'cover'
        }
      }>
      <Grid
        className={
          BannerFadingEffect?.value ? 'back-fadding' : 'back-fadding-none'
        }>
        <Container className="title-img-wrapper">
          <Box className="ground-heading">
            <Text field={Heading} />
          </Box>
          <Box className="">
            <Image field={ForegroundImage} />
          </Box>
        </Container>
      </Grid>
    </Box>
  );
};

const Testimony = ({ fields }) => {
  const dateOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };
  const [width] = useWindowSize();
  const Card = (Content) => {
    return (
      <>
        {Content?.map((card, i) => {
          const [readMore, setReadMore] = useState(false);
          const fixedContent = {
            value: card?.fields?.Description.value.slice(0, 400)
          };
          return (
            <Grid item md={6} lg={6} sm={12} xs={12} key={i}>
              <Box className="rating-card">
                <div className="title-star-wrapper">
                  <Box>
                    <Typography className="card-title">
                      <p>{card.fields.Title.value}</p>
                    </Typography>
                    <Typography className="card-aname">
                      <Text field={card?.fields?.AgencyName} />
                    </Typography>
                  </Box>
                  <Box className="rating-img">
                    <Image field={card?.fields?.RatingImage} />
                  </Box>
                </div>
                <Typography className="card-description">
                  {readMore || width > 600 ? (
                    <RichText field={card?.fields?.Description} />
                  ) : (
                    <RichText field={fixedContent} />
                  )}
                  {card?.fields?.Description.value.length > 400 &&
                  width < 600 ? (
                    <a onClick={() => setReadMore(!readMore)}>
                      {readMore ? '...see less' : '...see more'}
                    </a>
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography className="date-wrapper">
                  <DateField
                    field={card?.fields?.Date}
                    render={(date) => date.toLocaleString('en-ca', dateOptions)}
                  />
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </>
    );
  };
  return (
    <Box
      className="testimonial-container"
      role="region"
      aria-label="testimonial">
      <Grid>
        <HeroBanner
          BackgroundImage={fields?.BackgroundImage}
          Heading={fields?.Heading}
          ForegroundImage={fields?.ForegroundImage}
          BannerFadingEffect={fields?.BannerFadingEffect}
        />
        <Container>
          <Box className="card-container">
            <Grid container item md={12} lg={12}>
              {Card(fields.TestimonialsList)}
            </Grid>
          </Box>
        </Container>
      </Grid>
    </Box>
  );
};

export default Testimony;
