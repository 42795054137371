const resultsPerPage = 10;
const resultsPerPageNews = 6;
const resultsOnPageCalc = resultsPerPage - 1;
const specialChars = /^[a-zA-Z0-9À-ÖØ-öø-ÿ`'’ \s]*$/;
const removespecialChars = /[<>"&,/]/g;
const searchSessionQuery = 'searchQuery';
const findAnAgecy = 'find an agency';
const menuBaseType = 'menu';
const dateOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
};

// dictionary service keys start
const noResult = 'noResult';
const searchText = 'search';
const noResultEnterLeastCharacter = 'noResultEnterLeastCharacter';
const Mobile_language = 'Mobile_language';
const Mobile_province = 'Mobile_province';
const paginationText = 'paginationText';
const resultsFound = 'resultsFound';
const result = 'result';
const search_keyword = 'search_keyword';
const About = 'About';
const NoresultSpecialChar = 'NoresultSpecialChar';

// dictionary service keys end

// api url's

const searchApiUrl = '/api/Sitecore/Search/GetGlobalSearchResults';
const newsRoomApiUrl = '/api/Sitecore/Search/GetNewDetailsResult';
const newsRoomApiHomepage = '/api/Sitecore/Search/GetHomeNewDetailsResult';
// https://acg-cm.dev.local/api/Sitecore/Search/GetNewDetailsResult?query=home&pageNumber=1&pageSize=10

const LangFR = 'fr-CA';
const LangEN = 'en-CA';
const geoUrl = '/api/sitecore/state/PersonalizeData';

export {
  resultsPerPage,
  resultsPerPageNews,
  resultsOnPageCalc,
  specialChars,
  removespecialChars,
  searchSessionQuery,
  noResult,
  searchText,
  noResultEnterLeastCharacter,
  Mobile_language,
  Mobile_province,
  paginationText,
  resultsFound,
  result,
  search_keyword,
  searchApiUrl,
  About,
  findAnAgecy,
  menuBaseType,
  newsRoomApiUrl,
  dateOptions,
  newsRoomApiHomepage,
  LangFR,
  LangEN,
  NoresultSpecialChar,
  geoUrl
};
