import React, { useState, useEffect } from 'react';
import { getBaseURL } from '.';

export const useHttp = ({ apiUrl, query, noQueryParam = false }) => {
  const initialErrorState = {
    error: false,
    errorMessage: null
  };
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [{ error, errorMessage }, setError] = useState(initialErrorState);
  const fetchData = async () => {
    setFetching(true);
    const baseURL = getBaseURL();
    const Url = noQueryParam
      ? `${baseURL}${apiUrl}`
      : `${baseURL}${apiUrl}${query}`;
    try {
      const data = await fetch(`${Url}`);
      const json = await data.json();
      setData(json);
    } catch (err) {
      setError({
        error: true,
        errorMessage: err.message
      });
      console.log('error, something went wrong');
    }
    setFetching(false);
  };
  useEffect(() => {
    if (apiUrl && query) {
      fetchData({ apiUrl, query });
    } else if (apiUrl && noQueryParam) {
      fetchData({ apiUrl });
    }
  }, [apiUrl, query]);

  return { data, fetching, error, errorMessage };
};
