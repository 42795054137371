import React, { useState } from 'react';
import { AlertContext } from './alertContext';

const AlertProvider = ({ children }) => {
  const [hasAlert, setHasAlert] = useState(false);

  return (
    <AlertContext.Provider
      value={{
        hasAlert,
        setHasAlert
      }}>
      {children}
    </AlertContext.Provider>
  );
};
export default AlertProvider;
