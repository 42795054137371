import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AllstateButton from '../common/Button';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import GetAQuoteDropDown from './GetAQuoteDropDown';
import SearchBox from './SearchBox';
import './HeroBannerHome.scss';
import { Container } from '@mui/material';
import HTagComponent from '../common/HTagComponent/HTagComponent';

const HeroBannerHome = (props) => {
  const {
    fields: {
      BannerType: {
        fields: {
          Text: { value: _VARIANT }
        }
      },
      Image: {
        value: { src: bannerBG, alt: bannerBGAlt }
      },
      Image2,
      ImageTitle,
      ImageTopDescription,
      ImageBottomDescription,
      Icon,
      Link,
      ImageTitleHTag,
      ImageTopDescriptionHTag
    }
  } = props;

  return (
    <>
      <Paper
        sx={{
          backgroundImage: `url(${bannerBG})`,
          height: `${Image2?.value?.height}${'px'}`
        }}
        // className={`HeroBanner ${_VARIANT === 'home' ? 'HeroBannerHome' : ''}`}>
        className={`HeroBanner ${''}`}>
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: 'none' }}
            src={bannerBG}
            alt={bannerBGAlt}
            loading="lazy"
          />
        }
        <Container
          sx={{ position: 'relative' }}
          role="region"
          aria-label="herobanner">
          <Grid container className="HeroBannerContainer">
            <Grid item md={12}>
              <Box className="HeroBannerBox">
                {ImageTitleHTag?.value !== '' && ImageTitleHTag ? (
                  <HTagComponent
                    hTag={ImageTitleHTag}
                    TitleText={ImageTitle}
                    alignText={'left'}
                  />
                ) : (
                  <>
                    {ImageTitle?.value && (
                      <Typography
                        component="h1"
                        variant="h3"
                        color="inherit"
                        gutterBottom
                        className="acaHBHomeTitle">
                        <RichText field={ImageTitle} />
                      </Typography>
                    )}
                  </>
                )}

                {ImageTopDescription?.value && (
                  <Typography
                    variant="h5"
                    color="inherit"
                    paragraph
                    className="acaHBHomePara">
                    <RichText field={ImageTopDescription} />
                  </Typography>
                )}

                {(() => {
                  if (_VARIANT === 'search') {
                    return <SearchBox {...props} />;
                  } else {
                    if (Icon.length > 0) {
                      return <GetAQuoteDropDown {...props} />;
                    } else {
                      return (
                        <>
                          {Link?.value?.href !== '' ? (
                            <AllstateButton
                              className="hero-banner-button"
                              size="medium"
                              content={Link}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    }
                  }
                })()}
                {ImageBottomDescription?.value && (
                  <Typography
                    variant="h4"
                    color="inherit"
                    paragraph
                    className="acaHBHomePara">
                    <RichText field={ImageBottomDescription} />
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          {Image2?.value?.src && (
            <div className="productImage hidden-mobile hidden-tablet">
              <Image field={Image2} loading="lazy" />
            </div>
          )}
        </Container>
      </Paper>
    </>
  );
};

HeroBannerHome.propTypes = {
  fields: PropTypes.shape({
    BannerType: PropTypes.object,
    image: PropTypes.string,
    image2: PropTypes.string,
    ImageTitle: PropTypes.object.isRequired,
    ImageTopDescription: PropTypes.object.isRequired,
    ImageBottomDescription: PropTypes.object.isRequired
  }).isRequired
};

export default HeroBannerHome;
