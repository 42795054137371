import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Container } from '@mui/material';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import BulletPoint from '../../common/BulletPoint/BulletPoint';
import './videocontent.scss';

const VideoContent = (props) => {
  const [show, setShow] = useState(false);
  const {
    VideoUrl = {},
    IsVideoEnabled = false,
    ListItem = [],
    CTASummary = {},
    Transcript = '',
    HideText = '',
    ShowText = ''
  } = props;
  const iframeUrl = VideoUrl?.value?.href;

  const handleClick = (event) => {
    setShow(!show);
  };
  return (
    <>
      {IsVideoEnabled && (
        <Container>
          <Grid container direction="row" xs={12} className="video-wrapper">
            <Grid item sm={12} md={6}>
              <iframe
                className="iframe-video"
                src={iframeUrl}
                title="video Content"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
              <Typography className="video-button-typography">
                {HideText?.value && ShowText?.value && (
                  <button
                    className="video-buttonlink"
                    onClick={(e) => handleClick(e)}>
                    {show ? HideText?.value : ShowText?.value}
                  </button>
                )}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography className="video-desc">
                <Text field={CTASummary} />
              </Typography>
              <BulletPoint content={ListItem} bulletStyle="normal-list" />
            </Grid>
          </Grid>
          {show && (
            <Typography className="video-transcript-desc">
              <Text field={Transcript} />
            </Typography>
          )}
        </Container>
      )}
    </>
  );
};

export default VideoContent;
