import React, { useEffect } from 'react';
import { MenuItem, Typography, Menu, Button } from '@mui/material';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import dropdownBlue from '../../../../assets/downarrow-blue.svg';
import './SelectedMenu.scss';

const SelectedMenu = (props) => {
  const {
    options,
    startIcon,
    altText,
    handleSelect,
    keyDrop,
    language,
    geoLocation
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    handleSelect(event.currentTarget.textContent);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const returnlocationindex = (geoLocation) => {
    switch (geoLocation) {
      case 'Alberta':
        return 0;
      case 'Ontario':
        return 1;
      case 'Quebec':
        return 2;
      case 'NewBrunswick':
        return 3;
      case 'NovaScotia':
        return 4;
      case 'AB':
        return 0;
      case 'ON':
        return 1;
      case 'QC':
        return 2;
      case 'NB':
        return 3;
      case 'NS':
        return 4;
      case 'Québec':
        return 2;
      case 'Nouveau-Brunswick':
        return 3;
      case 'Nouvelle-É cosse':
        return 4;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (keyDrop === 'Lang') {
      setSelectedIndex(
        language !== undefined &&
          language.toLowerCase() === 'fr-CA'.toLowerCase()
          ? 1
          : 0
      );
    }
    if (keyDrop === 'Geo') {
      // console.log('<<<<<<<<<<<<<<< GeoLocation Layout Service', geoLocation);
      setSelectedIndex(returnlocationindex(geoLocation));
    }
  }, [selectedIndex]);

  const renderValue = (type, value) => {
    const lang =
      language !== undefined && language.toLowerCase() === 'fr-CA'.toLowerCase()
        ? 'FR'
        : 'EN';
    const valRet = type === 'Lang' ? lang : value;
    return valRet;
  };
  return (
    <div role="region" aria-label="dropdown">
      <Button
        // id="demo-customized-button"
        id={keyDrop}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label={options[selectedIndex]?.fields?.Link?.value?.text}
        variant="tertiary"
        disableElevation
        onClick={handleClickListItem}
        startIcon={
          <img
            src={startIcon}
            alt={altText}
            loading="lazy"
            height={18}
            width={18}
          />
        }
        endIcon={
          <img
            src={dropdownBlue}
            alt={altText}
            loading="lazy"
            height={5}
            width={9}
          />
        }
        tabIndex={0}
        className="acaSelectedMenubtn">
        {renderValue(
          keyDrop,
          options[selectedIndex]?.fields?.Link?.value?.text
        )}
        {/* {localStorage.getItem('selectLang')} */}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}>
        {options.map((option, index) => (
          <MenuItem
            className="menu-dropdown-list"
            key={`panel${index}`}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}>
            <Typography className="menu-dropdown">
              {option?.fields?.Name?.value
                ? option?.fields?.Name?.value
                : option?.displayName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default SelectedMenu;
