import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useWindowSize } from '../../../utils/useWindowSize';
import { paginationText } from '../../constants';
import Stack from '@mui/material/Stack';
import './pagination.scss';

export const StyledPagination = (props) => {
  const {
    resultsPerPage = 10,
    count = 1,
    variant = '',
    siblingCount = 1,
    route,
    page,
    TotalResults,
    pathname,
    searchKeyword,
    onPageChange = undefined,
    t,
    ...rest
  } = props;
  const initialNumber = page === 1 ? 1 : (page - 1) * resultsPerPage + 1;
  const resultsOnPageCalc = resultsPerPage - 1;
  const finalNumber =
    initialNumber + resultsOnPageCalc > TotalResults
      ? TotalResults
      : initialNumber + resultsOnPageCalc;
  const onPaginationChange = (e, page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (onPageChange) {
      onPageChange(page); // callback function to be passed from parent if required
    }
  };
  const isMobile = useWindowSize() < 700;
  const custompagination = () => {
    return (
      <span className="mobile-pagination">
        <p className="selected">page {page} </p>
        <p className="total">&nbsp;/ {count}</p>
      </span>
    );
  };
  const resultsText = t(paginationText)
    ?.replace('$${TotalResults}', TotalResults)
    ?.replace('$${pageRange}', `${initialNumber}-${finalNumber}`);
  return (
    <Stack spacing={2}>
      <p className="results-text">{resultsText}</p>
      <Pagination
        className="pagination"
        page={page}
        size="medium"
        count={count}
        onChange={onPaginationChange}
        {...rest}
        renderItem={(item) => {
          if (item.selected && isMobile) {
            return custompagination();
          } else {
            return (
              <PaginationItem
                component={Link}
                to={`${pathname}${
                  item.page === 1
                    ? `?q=${searchKeyword}`
                    : `?page=${item.page}&q=${searchKeyword}`
                }`}
                {...item}
              />
            );
          }
        }}
      />
    </Stack>
  );
};

export default withTranslation()(StyledPagination);
