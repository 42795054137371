import React from 'react';
import Box from '@mui/material/Box';
import TabPanel from '../common/TabPanel';
import { useWindowSize } from '../../utils/useWindowSize';
import AllstateTabs from '../common/TabComponent';
import ListCollectionLayout from '../common/ListCollectionLayout';
// import LinearLayout from '../common/LinearLayout';
import Heading from '../common/HeadingComponent';
import './ProductTab.scss';
import { Container } from '@mui/material';

const ProductTab = ({ fields }) => {
  const [width] = useWindowSize();
  const [cardvalue, setShowCard] = React.useState(0);
  const [tabList, setTabList] = React.useState([]);
  const { Caption = null, Title = null } = fields;

  React.useEffect(() => {
    // Developer Note: Re-create object as per AllstateTabs component required.
    const _list = fields?.List?.map((val) => {
      return {
        fields: { Image: val.fields.Image, ImageCaption: val.fields.TabTitle }
      };
    });
    setTabList(_list);
  }, []);

  const handleChangeCallback = (cardValue) => {
    setShowCard(cardValue);
  };

  const fitTabtoScreen = tabList?.length < 4;
  const tabVariant = width > 600 || fitTabtoScreen ? 'fullWidth' : 'scrollable';
  const a11yProps = (index) => {
    return {
      id: `explore-tab-${index}`,
      'aria-controls': `explore-tabpanel-${index}`
    };
  };
  // const mt = fitTabtoScreen ? { xs: 2, md: 4 } : { xs: -6, md: -10 };
  // to change based on flag and sx to be removed
  return (
    <Box
      className="thirdlevel-page-tabcontent"
      role="region"
      aria-label="productTab">
      <Box
        className="explore-wrapper section-padding"
        sx={{
          p: { xs: 2, md: 6 },
          mx: { xs: 1, md: 4 },
          mt: { xs: 2, md: 4 },
          position: 'relative',
          borderRadius: 1
        }}>
        <Container className="mobile-padzero">
          {Title?.value !== '' ? (
            Title && <Heading caption={Caption} heading={Title} />
          ) : (
            <></>
          )}
          {tabList?.length > 0 && (
            <AllstateTabs
              fitTabtoScreen={fitTabtoScreen}
              tabVariant={tabVariant}
              tabCategories={tabList}
              tabButtona11yProps={a11yProps}
              tabButtonClassName={'exploreSectionTab'}
              tabClassName={'exploreSectionTabs'}
              scrollButtons={false}
              aria-label="explore options tab"
              handleChangeCallback={handleChangeCallback}
            />
          )}
        </Container>
      </Box>

      {fields?.List?.map((tab, index) => {
        const { ListComponent } = tab?.fields;
        return (
          <TabPanel
            key={`exploresection-tabPanel ${index}`}
            value={cardvalue}
            index={index}>
            <ListCollectionLayout
              lefttitle={ListComponent[0]?.fields?.LeftColText}
              leftbuttonDetails={ListComponent[0]?.fields?.LeftColLink}
              Title={ListComponent[0]?.fields?.Title}
              ListImage={ListComponent[0]?.fields?.ListImage}
              Description={ListComponent[0]?.fields?.Description}
              content={ListComponent[0]?.fields?.ListItem}
              BottomListText={ListComponent[0]?.fields?.BottomListText}
            />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default ProductTab;
