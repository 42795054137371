// eslint-disable-next-line import/no-extraneous-dependencies
import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import './JssForm.scss';
import { Container, Grid, Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';
const JssForm = ({ fields }) => {
  useEffect(() => {
    let element = document.getElementById(
      'fxb_660bab2d-eed5-4766-9764-4b361b996025_6c5b05f3-f9a0-4c72-867c-a8c25f088a30'
    );

    element.onclick = function () {
      window.open(`${window.location.href}`, '_self');
    };
  }, []);
  return (
    <Container>
      <Grid container className="jssform-page-container">
        <Grid item sm={12} md={3} lg={3}></Grid>
        <Grid item sm={12} md={9} lg={9}>
          <Box className="jss-form-container">
            <Form
              form={fields}
              sitecoreApiHost={sitecoreApiHost}
              sitecoreApiKey={sitecoreApiKey}
              // onRedirect={(url) => history.push(url)}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default JssForm;
