import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PageInfoWithIcon from './PageInfoWithIcon';
import RichTextComponent from '../common/RichTextComponent';
import './MediaWithText.scss';
import { Container } from '@mui/material';

const MediaWithText = ({ fields }) => {
  return (
    <Box
      className="PageIntroductionSection"
      role="region"
      aria-label="mediawithtext">
      <Container>
        <Grid container>
          <Grid className="PageInfoWithIcon-Section" item sm={12} md={3} lg={3}>
            <PageInfoWithIcon data={fields} />
          </Grid>
          <Grid item sm={12} md={9} lg={9}>
            <Box className="leftContent">
              <RichTextComponent content={fields?.Text} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

MediaWithText.propTypes = { fields: PropTypes.object.isRequired };

export default MediaWithText;
