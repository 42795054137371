import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Grid
} from '@mui/material';
import getquotedroparrow from '../../../assets/getquotedroparrow.svg';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import './GetAQuoteDropDown.scss';

const GetAQuoteDropDown = (props) => {
  const { Icon: options, ButtonText } = props.fields;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    const target =
      options[selectedIndex]?.fields?.TargetUrl?.value?.href !== ''
        ? options[selectedIndex]?.fields?.TargetUrl?.value?.href
        : '_blank';
    window.open(
      options[selectedIndex].fields.TargetUrl.value.href,
      Math.random()
    );
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        role="region"
        aria-label="splitbutton"
        size="large"
        disableElevation
        className="getAQuoteBtnGroup">
        <Button
          tabIndex={0}
          role="button"
          variant="text"
          className="btnToggle"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label={options[selectedIndex]?.fields?.ImageCaption?.value}
          aria-haspopup="menu"
          onClick={handleToggle}
          disableRipple={true}
          sx={{ p: 0 }}
          fullWidth>
          <Grid
            container
            direction={'row'}
            justifyContent={'space-between'}
            alignContent={'center'}
            flexWrap={'nowrap'}>
            <Grid
              container
              direction={'row'}
              justifyContent={'flex-start'}
              alignContent={'center'}
              flexWrap={'nowrap'}>
              <Grid item className={'grid-item'}>
                <Image
                  field={options[selectedIndex]?.fields?.Image}
                  height={39}
                  width={39}
                />
              </Grid>
              <Grid item className={'grid-item selectedProduct'}>
                <Typography variant="subtitle1" component={'span'}>
                  <Text field={options[selectedIndex]?.fields?.ImageCaption} />
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={'grid-item'}>
              <img src={getquotedroparrow} alt="" height={8} width={19} />
            </Grid>
          </Grid>
        </Button>
        <Popper
          sx={{
            zIndex: 1
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          keepMounted={true}
          placement="bottom-start"
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}>
              <Paper sx={{ p: 2, boxShadow: 3 }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem tabIndex={1}>
                    {options.map((option, index) => (
                      <MenuItem
                        tabIndex={0}
                        key={`panel${index}`}
                        className="menu-dropdown-list"
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        <Typography className="menu-dropdown">
                          <Text field={option?.fields?.ImageCaption} />
                        </Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          tabIndex={0}
          onClick={handleClick}
          disableRipple={true}
          variant="contained"
          aria-label={ButtonText?.value}
          className="getAQuoteBtnHalfRounded">
          <Text field={ButtonText} />
        </Button>
      </ButtonGroup>
    </React.Fragment>
  );
};

export default GetAQuoteDropDown;
