import * as React from 'react';
import PropTypes, { object } from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import arrowbreadcrumb from '../../assets/arrowbreadcrumb.svg';
import './BreadcrumbBar.scss';
import { Container, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { getBaseURL } from '../../utils';
const BreadcrumbBar = ({ fields, H1Tag }) => {
  const [url, setUrl] = React.useState(false);
  const [basenewUrl, setBaneUrl] = React.useState(false);

  React.useEffect(() => {
    setUrl(window?.location?.href + '/#breadcrumb');
    setBaneUrl(getBaseURL());
  }, []);

  const returnListItem = (list) => {
    var result = list?.map(
      (item, i) =>
        `{"type": "ListItem","position": "${i}","name":"${item?.text}","item":"${basenewUrl}${item?.url}"}`
    );
    return result;
  };

  const breadcrumscript = `{"@context": "https://schema.org","@type": "BreadcrumbList","@id":"${url}","itemListElement":[${returnListItem(
    fields?.itemData?.fields?.breadcrumbs
  )}]} `;

  const H1Value = { value: H1Tag };
  const breadcrumbsList = fields?.itemData?.fields?.breadcrumbs?.map(
    (data, index) => {
      if (index === fields?.itemData?.fields?.breadcrumbs?.length - 1) {
        const data1 = { value: data?.text };
        return (
          <Typography className="BreadCrumbText" key={index}>
            {/* {data?.text} */}
            <RichText field={data1} />
          </Typography>
        );
      } else {
        return (
          <Link
            className="BreadCrumbLink"
            underline="hover"
            key={index}
            color="inherit"
            href={data?.url}>
            {data?.text}
          </Link>
        );
      }
    }
  );

  return (
    <>
      <Helmet>
        {fields?.itemData?.fields?.backButtonUrl !== '/newsroom' && (
          <script type="application/ld+json">{breadcrumscript}</script>
        )}
      </Helmet>
      <Grid
        container
        className="BreadCrumbContainer"
        role="region"
        aria-label="Breadcrumbs">
        <Container className="BreadCrumbContainer-section">
          <Grid item md={12}>
            <Breadcrumbs
              className="BreadCrumbNav"
              separator={
                <img
                  src={arrowbreadcrumb}
                  height={12}
                  width={5}
                  alt=""
                  loading="lazy"
                />
              }
              aria-label="breadcrumb">
              {breadcrumbsList}
            </Breadcrumbs>
            {/* {H1Tag !== '' && (
              <h1 className="h1-tag-styling">
                <RichText field={H1Value} />
              </h1>
            )} */}
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

BreadcrumbBar.defaultProps = {
  fields: {
    itemData: {
      fields: { breadcrumbs: [] }
    }
  }
};

BreadcrumbBar.propTypes = {
  fields: PropTypes.shape({
    itemData: PropTypes.object.isRequired
  }).isRequired
};

export default BreadcrumbBar;
