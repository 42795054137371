import React, { useEffect } from 'react';
import { Image, Text, DateField } from '@sitecore-jss/sitecore-jss-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import Left_Icon from './assets/Left_Icon.svg';
import Right_Icon from './assets/Right_Icon.svg';
import Heading from '../common/HeadingComponent';
import { useHttp } from '../../utils/useHttp';
import { newsRoomApiHomepage as apiUrl } from '../constants';
import './index.scss';
import { Container } from '@mui/material';

const NewsCard = (props) => {
  const {
    fields: { Title, SubTitle },
    context: { language }
  } = props;

  const noQueryParam = true;

  const newsData = useHttp({ apiUrl, noQueryParam });
  const { data, fetching, error } = newsData;
  const newsCards = (data && data[0]?.Results) || null;

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={Right_Icon}
        className={className}
        style={{ ...style, height: '50px', width: '50px', right: '-70px' }}
        onClick={onClick}
        alt="next-arrow"
        loading="lazy"
        height={50}
        width={50}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={Left_Icon}
        className={className}
        style={{ ...style, height: '50px', width: '50px', left: '-70px' }}
        onClick={onClick}
        alt="prev-arrow"
        height={50}
        width={50}
      />
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const dateOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  };

  const renderCards = (cards) => {
    return cards.map((newsCard, i) => {
      const url = newsCard?.ItemPath?.split('/home')[1]; // to split full url from sitecore
      return (
        <Card
          key={`home-news-card-${i}`}
          className="card-container"
          sx={{
            maxWidth: 228,
            margin: 1,
            maxHeight: 320
          }}>
          <a href={url} className="link-reset" tabIndex={-1}>
            <img
              src={newsCard?.Article_Image}
              className="card-image"
              alt="ArticleImage"
            />
            <CardContent className="card-content" data-testid="card-content">
              <Typography gutterBottom component="div">
                <Typography
                  className="card-header"
                  component="p"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '4',
                    WebkitBoxOrient: 'vertical',
                    mb: 1.5
                  }}>
                  {newsCard?.Article_Page_Title}
                </Typography>
              </Typography>
              <Typography className="card-body" component="p">
                {newsCard?.Article_Location}
              </Typography>

              <Typography className="card-footer">
                <DateField
                  field={{ value: newsCard?.Article_Date }}
                  render={(date) => date.toLocaleString(language, dateOptions)}
                />
              </Typography>
            </CardContent>
          </a>
        </Card>
      );
    });
  };
  return (
    <Box className="hidden-mobile hidden-tablet news-section section-padding">
      <Container role="region" aria-label="newscard">
        <Heading caption={SubTitle} heading={Title} />
        {newsCards && (
          <div className="slider-container">
            <Slider {...settings}>{renderCards(newsCards)}</Slider>
          </div>
        )}
      </Container>
    </Box>
  );
};

export default NewsCard;
