import React from 'react';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { Box, Grid, createTheme, Container } from '@mui/material';
import ImageSwipeComponent from './ImageSwipeComponent';
import './SwipeImage.scss';

const SwipeImage = ({ fields }) => {
  return (
    <Box className="LeftRightJPGSection">
      {fields?.List?.map((data, index) => {
        const { Image, Description, Title, ImageOnLeft } = data?.fields;
        return (
          <ImageSwipeComponent
            key={`${index}`}
            Title={Title}
            SwipeImage={Image}
            Description={Description}
            ImageOnLeft={ImageOnLeft}
          />
        );
      })}
    </Box>
  );
};

export default SwipeImage;
