import React from 'react';
import MainContent from './MainContent';
import SubFooterComponent from './SubFooterComponent';
import { Box, Container, Grid } from '@mui/material';
import './MainContent.scss';

const Footer = ({ fields }) => {
  return (
    <Box>
      <Container
        fluid
        className="full-width pad-zero"
        role="region"
        aria-label="footer">
        <Grid className="TopFooterSection"></Grid>
        <Grid>
          <MainContent data={fields} />
        </Grid>
        <Grid className="full-width pad-zero-subfooter">
          <SubFooterComponent
            topButtonText={fields?.ScrollTopText}
            copyrightFooterLink={fields?.copyrightFooterLinks}
            socialFooterLinks={fields?.socialFooterLinks}
          />
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
