import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Typography from '@mui/material/Typography';
import AllstateButton from '../Button';
import './contentCTA.scss';
import HTagComponent from '../HTagComponent/HTagComponent';

const ContentCTA = ({ fields, headingTag }) => {
  console.log(headingTag, 'headingTag');
  return (
    <div
      className="CardSectionCol section-padding"
      role="region"
      aria-label="contentCTA">
      <Typography className="PolicyOptionsAndFeaturesText">
        {fields?.PromoTextHTag?.value !== '' && fields?.PromoTextHTag ? (
          <HTagComponent
            hTag={fields?.PromoTextHTag}
            TitleText={fields?.PromoText}
          />
        ) : (
          <RichText field={fields?.PromoText} />
        )}
      </Typography>
      {fields?.PromoLink?.value?.href && (
        <AllstateButton
          content={fields?.PromoLink}
          size="medium"
          className="cta-button"
        />
      )}
    </div>
  );
};

export default ContentCTA;
