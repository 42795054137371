import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Typography from '@mui/material/Typography';
import { Container, Grid, Box } from '@mui/material';
import AllstateButton from '../common/Button';
import './PrivacyPolicy.scss';

const PrivacyPolicy = ({ fields }) => {
  return (
    <Box className="privacy-wrapper">
      <Container>
        <Grid item sm={12} md={12} lg={12} className="Privacy-Container">
          {fields?.Header?.value !== '' && (
            <Typography className="Privacy-title-text">
              <Text field={fields?.Header} />
            </Typography>
          )}
          {fields?.Description?.value !== '' && (
            <Typography className="Privacy-desc-text">
              <RichText field={fields?.Description} />
            </Typography>
          )}
          {fields?.Button?.value?.text && (
            <AllstateButton
              content={fields?.Button}
              size="medium"
              className="Privacy-cta-button"
            />
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
