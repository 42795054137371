const getFAQData = (searchData, fieldsList) => {
  let List = [...fieldsList];
  if (searchData === null) {
    return List;
  }
  const categoryList = getCategoryList(searchData);

  // Step 1 : Collect all tabs question and answer
  categoryList.forEach((category) => {
    const AccordionList = getCategoryData(category, searchData);
    const categoryIndex = getCategoryIndex(category, List);

    if (categoryIndex !== -1) {
      List = setQuestionInOtherCategory(List, AccordionList, categoryIndex);
    }
  });

  // Step 2 : Collect first tab question and answer
  const allQuestionsList = getAllQuestions(searchData);
  List = setQuestionInAllCategory(List, allQuestionsList);

  return List;
};

const getCategoryList = (searchData) => {
  let categories = [];
  searchData.map((data) => {
    categories = [...categories, ...data.Category];
  });
  let uniqueCategories = categories.filter(
    (value, index, array) => array.indexOf(value) === index
  );

  return uniqueCategories;
};

const getCategoryData = (category, searchData) => {
  const AccordionList = searchData
    .filter((obj) => obj.Category.indexOf(category) !== -1)
    .map((obj) => {
      return {
        fields: {
          Question: {
            value: obj.Question
          },
          Text: {
            value: obj.Text
          }
        }
      };
    });

  return AccordionList;
};

const getAllQuestions = (searchData) => {
  const AccordionList = searchData.map((obj) => {
    return {
      fields: {
        Question: {
          value: obj.Question
        },
        Text: {
          value: obj.Text
        }
      }
    };
  });

  return AccordionList;
};

const getCategoryIndex = (category, List) => {
  return List.findIndex((value) => value?.fields?.TabTitle?.value === category);
};

const setQuestionInOtherCategory = (List, AccordionList, categoryIndex) => {
  const updatedArray = [
    ...List.slice(0, categoryIndex),
    {
      ...List[categoryIndex],
      fields: {
        ...List[categoryIndex].fields,
        // here update data value
        AccordionList: [...AccordionList]
      }
    },
    ...List.slice(categoryIndex + 1)
  ];

  return [...updatedArray];
};

const setQuestionInAllCategory = (List, AccordionList) => {
  /**
   * Set question in "All" category.
   */
  const updatedArray = [
    {
      ...List[0],
      fields: {
        ...List[0].fields,
        // here update data value
        AccordionList: [...List[0].fields.AccordionList, ...AccordionList]
      }
    },
    ...List.slice(1)
  ];

  return [...updatedArray];
};

export default getFAQData;
