import React from 'react';
import Box from '@mui/material/Box';
import AllstateButton from '../common/Button';
import TabPanel from '../common/TabPanel';
import CardsDesktop from './CardsDesktop';
import CardsMobile from './CardsMobile';
import { useWindowSize } from '../../utils/useWindowSize';
import Heading from '../common/HeadingComponent';
import AllstateTabs from '../common/TabComponent';
import VideoContent from './VideoContent';
import './ExploreSection.scss';
import { Container } from '@mui/material';
import HTagComponent from '../common/HTagComponent/HTagComponent';

const ExploreSection = ({ fields }) => {
  const [width] = useWindowSize();
  const {
    ExploreSectionLabel = null,
    ExploreSectionHeading = null,
    ExploreSectionHeadingHTag,
    ExploreSectionDescription = null,
    VariantFlag = null,
    ExploreAllButton,
    ExploreCategories,
    VideoCategories = null // this is not yet implemented in sitecore
  } = fields.exploresectionFields;

  const [cardvalue, setShowCard] = React.useState(0);
  const renderTab = ExploreCategories?.length > 0;

  const handleChangeCallback = (cardValue) => {
    setShowCard(cardValue);
  };

  const renderVideo = (videoList) => {
    return videoList.map((video, i) => {
      const videoFields = video?.fields;
      return <VideoContent key={video - `${i}`} {...videoFields} />;
    });
  };

  const fitTabtoScreen = ExploreCategories.length < 4;
  const tabVariant = width > 600 || fitTabtoScreen ? 'fullWidth' : 'scrollable';
  const a11yProps = (index) => {
    return {
      id: `explore-tab-${index}`,
      'aria-controls': `explore-tabpanel-${index}`
    };
  };
  const mt = fitTabtoScreen ? { xs: 2, md: 4 } : { xs: -6, md: -10 }; // to change based on flag and sx to be removed
  const check1 =
    ExploreSectionLabel?.value ||
    ExploreSectionDescription?.value ||
    ExploreSectionHeading?.value;

  const check2 = renderTab && ExploreCategories[0]?.fields?.ImageCaption?.value;
  return (
    <Box className="home-section-tab">
      {(check1 || check2) && (
        <Box
          className="explore-wrapper section-padding"
          sx={{
            p: { xs: 2, md: 6 },
            mx: { xs: 1, md: 4 },
            mt: VariantFlag?.value === 'Home' ? mt : 0,
            position: 'relative',
            borderRadius: 1
          }}>
          <Container className="mobile-padzero" role="region" aria-label="Tabs">
            {ExploreSectionHeadingHTag?.value !== '' &&
            ExploreSectionHeadingHTag ? (
              <>
                {ExploreSectionLabel?.value && (
                  <Heading
                    caption={ExploreSectionLabel}
                    className="explore-heading-wrapper"
                  />
                )}
                {ExploreSectionHeading?.value && (
                  <HTagComponent
                    TitleText={ExploreSectionHeading}
                    hTag={ExploreSectionHeadingHTag}
                    alignText="center"
                  />
                )}
                {ExploreSectionDescription?.value && (
                  <Heading
                    description={ExploreSectionDescription}
                    className="explore-heading-wrapper"
                  />
                )}
              </>
            ) : (
              <>
                {(ExploreSectionLabel?.value ||
                  ExploreSectionDescription?.value ||
                  ExploreSectionHeading?.value) && (
                  <Heading
                    caption={ExploreSectionLabel}
                    description={ExploreSectionDescription}
                    heading={ExploreSectionHeading}
                    className="explore-heading-wrapper"
                  />
                )}
              </>
            )}

            {renderTab &&
            ExploreCategories[0]?.fields?.ImageCaption?.value !== '' ? (
              <AllstateTabs
                fitTabtoScreen={fitTabtoScreen}
                tabVariant={tabVariant}
                tabCategories={ExploreCategories}
                tabButtona11yProps={a11yProps}
                tabButtonClassName={'exploreSectionTab'}
                tabClassName={'exploreSectionTabs'}
                scrollButtons={false}
                aria-label="explore options tab"
                handleChangeCallback={handleChangeCallback}
              />
            ) : (
              <></>
            )}
          </Container>
        </Box>
      )}

      {ExploreCategories?.map((tab, index) => {
        return (
          <TabPanel
            role="region"
            aria-label="TabPanel"
            key={`exploresection-tabPanel ${index}`}
            value={cardvalue}
            index={index}>
            {tab?.fields?.ExploreCTAList?.length > 0 && (
              <>
                {width > 600 ? (
                  <CardsDesktop cardsData={tab?.fields?.ExploreCTAList} />
                ) : (
                  <CardsMobile cardsData={tab?.fields?.ExploreCTAList} />
                )}
              </>
            )}
            {tab?.fields?.ExploreVideoList?.length > 0 && (
              <>{renderVideo(tab.fields.ExploreVideoList)}</>
            )}
          </TabPanel>
        );
      })}
      {/* to render video categories if tabs are not present */}
      {VideoCategories?.length > 0 && <>{renderVideo(VideoCategories)}</>}
      {/* video end*/}
      {ExploreAllButton?.value?.text && (
        <div className="exploreMoreBtn">
          <AllstateButton
            content={ExploreAllButton}
            size="medium"
            lebel={ExploreAllButton?.value?.text}
            className="explore-more-btn"
          />
        </div>
      )}
    </Box>
  );
};

export default ExploreSection;
