import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AllstateButton from '../common/Button';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import './ClaimBannerSection.scss';

const ClaimBannerSection = ({ fields }) => {
  const {
    BannerClass,
    Image: bannerImage,
    Image: {
      value: { src: bannerBG }
    },
    Image2,
    ImageTitle,
    ImageTopDescription,
    ImageBottomDescription,
    Link: ExploreAllButton
  } = fields;
  return (
    <Box
      sx={{ backgroundColor: '#FBFBFB', p: { xs: 0, md: 2 } }}
      role="region"
      aria-label="claimbanner">
      <Paper
        sx={{
          backgroundImage: { md: `url(${bannerBG})`, xs: 'none' }
        }}
        className={`ClaimBannerSection ${BannerClass.value}`}>
        <div className="hidden-all">
          <Image field={bannerImage} loading="lazy" />
        </div>
        <Grid container>
          <Grid item xs={12} sx={{ display: { md: 'none' } }}>
            <Image field={Image2} loading="lazy" />
          </Grid>
          <Grid item xs={12} md={6} className="leftItem">
            <Typography className="left-item-title" color="inherit">
              <RichText field={ImageTitle} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="rightItem">
            {ImageTopDescription?.value !== '' ? (
              <Typography variant="h6" color="inherit">
                <RichText field={ImageTopDescription} />
              </Typography>
            ) : (
              <></>
            )}
            <Typography variant="body1" color="inherit">
              <RichText field={ImageBottomDescription} />
            </Typography>
            <AllstateButton
              content={ExploreAllButton}
              variant="small"
              isLink={true}
              buttonTextclasses="ClaimBannerBtn"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ClaimBannerSection;
