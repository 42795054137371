import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Helmet } from 'react-helmet';
import Heading from '../common/HeadingComponent';
import AllstateButton from '../common/Button';
import StyledPagination from '../common/Pagination';
import { useSearchParams, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  resultsPerPage,
  specialChars,
  removespecialChars,
  noResult,
  searchText,
  noResultEnterLeastCharacter,
  searchSessionQuery,
  searchApiUrl,
  search_keyword,
  resultsFound,
  result,
  NoresultSpecialChar
} from '../constants';
import { getBaseURL } from '../../utils';
import './searchdata.scss';

export const SearchData = ({ t }) => {
  const location = useLocation();
  const { pathname } = location;
  const [searchValue, setSearchValue] = useState(''); // control input value
  const [searchParams, setSearchParams] = useSearchParams();
  // const query = searchParams.get('query');
  const pageNumber = searchParams?.get('page') || 1;
  const pageSize = searchParams?.get('size') || resultsPerPage;
  const [searchQuery, setSearchQuery] = useState(''); // control api query
  const [error, setError] = useState(false);
  const [errorMinChar, setErrorMinChar] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [url, setUrl] = React.useState(false);
  useEffect(() => {
    setUrl(window?.location?.href);
  }, []);
  const fetchData = async ({ searchQuery, pageNumber, pageSize }) => {
    const baseURL = getBaseURL();
    try {
      const data = await fetch(
        `${baseURL}${searchApiUrl}?query=${searchQuery?.replaceAll(
          removespecialChars,
          ''
        )}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      const json = await data.json();
      setSearchData(json);
    } catch {
      console.log('error, something went wrong');
    }
  };

  const resetState = () => {
    setSearchData(null);
  };

  const queryValue = (SessionValue, StringValue) => {
    let returnString = '';
    console.log('>>>>>>>>>>>>>input Values', SessionValue, StringValue);
    if (SessionValue !== null) {
      if (SessionValue !== StringValue) {
        returnString = StringValue;
      } else {
        returnString = SessionValue;
      }
    } else {
      returnString = StringValue;
    }
    console.log('>>>>>>>>>>>>>returnString', returnString);
    return returnString;
  };
  useEffect(() => {
    // to update default value independent of Api query on mount
    const searchParam = new URLSearchParams(window.location.search);
    const query = queryValue(
      sessionStorage.getItem('searchQuery'),
      searchParam.get('q')
    );
    // sessionStorage.getItem('selectLang') !== null
    //   ? sessionStorage.getItem(searchSessionQuery)
    //   : searchParam.get('q') || '';

    setSearchValue(query);
    setSearchQuery(query);
  }, []);
  useEffect(() => {
    resetState();
    if (searchQuery) {
      fetchData({ searchQuery, pageNumber, pageSize });
      sessionStorage.setItem('searchQuery', searchQuery);
    }
  }, [searchQuery, pageNumber]);
  // useEffect(() => {
  //   window.open(`${window.location.href}?q=${searchQuery}`, '_self');
  // }, []);

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const searchButton = {
    value: t(searchText)
  };

  const searchResults = (searchData && searchData[0]?.Results) || null;
  const searchedText = searchData && searchData[0]?.SearchedText;

  const TotalResults = searchData && searchData[0]?.TotalResults;
  const resultsText = t(resultsFound)
    ?.replace('$${TotalResults}', TotalResults)
    ?.replace('$${SearchParam}', `'${searchedText}'`);

  const title = {
    value: resultsText
  };

  const validateInput = (e) => {
    const inputVal = searchValue?.trim();
    window?.location?.replace('/globalsearch?q=' + inputVal);
    if (!specialChars.test(inputVal)) {
      e.preventDefault();
      setError(true);
      setErrorMinChar(false);
    } else if (inputVal?.length < 3) {
      e.preventDefault();
      setError(false);
      setErrorMinChar(true);
    } else {
      setError(false);
      setErrorMinChar(false);
      setSearchQuery(inputVal);
      setSearchParams('');
    }
  };
  const renderContent = (searchResults) => {
    return searchResults?.map((searchResult, i) => {
      const { URL, Title, Description } = searchResult;
      return (
        <li className="result padtb-12" key={i}>
          {Title && (
            <a className="td-none" href={`${URL}`}>
              <Typography className="search-title">{Title}</Typography>
            </a>
          )}
          <a className="td-none" href={`${URL}`}>
            <Typography className="search-link">
              {window?.location?.protocol}
              {'//'}
              {window?.location?.hostname}
              {URL}
            </Typography>
          </a>
          <Typography className="search-description">{Description}</Typography>
        </li>
      );
    });
  };

  const handleKeyDown = (e) => {
    e.key === 'Enter' && validateInput(e);
  };

  const renderPagination = () => {
    const count =
      TotalResults % resultsPerPage === 0
        ? TotalResults / resultsPerPage
        : Math.floor(TotalResults / resultsPerPage) + 1;
    return (
      <Box className="pagination-wrapper">
        <StyledPagination
          count={count}
          page={Number(pageNumber)}
          pathname={pathname}
          TotalResults={TotalResults}
          searchKeyword={searchQuery}
        />
      </Box>
    );
  };

  const renderNoResults = () => {
    return (
      <Box>
        <Typography className="no-results">{t(noResult)}</Typography>
      </Box>
    );
  };

  const label = 'search';

  const searchPage = `{"@context": "https://schema.org","url": "https://www.allstate.ca/","@type": "WebSite",
  "potentialAction": {"@type": "SearchAction","target": "${url}","query-input": "q=${searchQuery}"}`;

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{searchPage}</script>
      </Helmet>
      <Container
        className="search-page-container section-padding"
        role="region"
        aria-label="searchpage">
        <Grid xs={10} md={10} lg={10}>
          <OutlinedInput
            value={searchValue?.replaceAll(removespecialChars, '')}
            onChange={onSearchChange}
            onKeyDown={handleKeyDown}
            error={error}
            id="globalsearch-input"
            inputProps={{
              'aria-label': t(searchText),
              placeholder: t(search_keyword),
              maxLength: 50
            }}
            className="search-input-bar"
            endAdornment={
              <AllstateButton
                onClick={(e) => validateInput(e)}
                variant="search"
                isLink={false}
                className="search-input-button"
                content={searchButton}
                lebel={label}
              />
            }
          />
          {searchResults && !error && !errorMinChar && (
            <Heading
              className="mt-50 hidden-mobile"
              alignText="left"
              heading={title}
              components={{ headingTag: 'h1' }}
            />
          )}
          {searchResults && (
            <Box className="mt-50 mobile-heading-wrapper">
              <Typography className="results-count_mobile">
                {TotalResults} {t(result)}
              </Typography>
              <Typography>{`'${searchedText}'`}</Typography>
              <span className="divider-bottom"></span>
            </Box>
          )}
          {error && !errorMinChar && (
            <Typography className="error-text">
              {t(NoresultSpecialChar)}
            </Typography>
          )}
          {!error && errorMinChar && (
            <Typography className="error-text">
              <p>{t(noResultEnterLeastCharacter)}</p>
            </Typography>
          )}
          {TotalResults > 0 && !error && !errorMinChar && (
            <Box>
              <ul className="search-results">
                {renderContent(searchData[0]?.Results)}
              </ul>
            </Box>
          )}
          {searchResults && TotalResults === 0 && renderNoResults()}
          {/* <Text field={props.fields.heading} /> */}
          {TotalResults > 10 &&
            !error &&
            !errorMinChar &&
            renderPagination(searchData)}
        </Grid>
      </Container>
    </>
  );
};

export default withTranslation()(SearchData);
