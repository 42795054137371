import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import './button.scss';
import { useState } from 'react';

const AllstateButton = (props) => {
  const [isClicked, setIsClicked] = useState(false);
  const {
    variant = 'primary',
    size = 'small',
    content = {},
    className = '',
    buttonWrapperClass = '',
    buttonTextclasses = '',
    isLink = true,
    lebel = '',
    ...rest
  } = props;

  const buttonTag = isLink ? 'a' : 'button';
  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    // Old code
    // <Box className={`button ${buttonWrapperClass}`}>
    //   <Button
    //     component={buttonTag}
    //     className={`button-${variant} ${className}`}
    //     {...rest}
    //     aria-label={lebel}>
    //     <Typography className={`button-${size} ${buttonTextclasses}`}>
    //       {isLink ? (
    //         <Link tabIndex={-1} field={content} />
    //       ) : (
    //         <Text tag="p" className="mb-reset" field={content} />
    //       )}
    //     </Typography>
    //   </Button>
    // </Box>
    <Box className={`button ${buttonWrapperClass}`}>
      {isLink ? (
        // <Button
        //   className={`button-${variant} ${className}`}
        //   {...rest}
        //   aria-label={lebel}>
        //   <Typography className={`button-${size} ${buttonTextclasses}`}>
        //     <Link className="btn-link" field={content} tabIndex={-1} />
        //   </Typography>
        // </Button>
        <Button
          href={content?.value?.href}
          target={content?.value?.target}
          onClick={handleClick}
          sx={{
            boxShadow: isClicked ? '0px 4px 4px 0px #00000033 inset' : 'none'
          }}
          className={`button-${variant} ${className}`}
          {...rest}
          aria-label={lebel}
          role="region">
          <Typography className={`button-${size} ${buttonTextclasses}`}>
            {content?.value?.text}
          </Typography>
        </Button>
      ) : (
        <Button
          className={`button-${variant} ${className}`}
          {...rest}
          aria-label={lebel}
          // onClick={handleClick}
          style={{
            boxShadow: isClicked ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none'
          }}>
          <Typography className={`button-${size} ${buttonTextclasses}`}>
            <Text
              tag="p"
              aria-label={lebel}
              role="region"
              className="mb-reset"
              field={content}
            />
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default AllstateButton;
