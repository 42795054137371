import React from 'react';
import Slider from 'react-slick';
import ExploreCard from './ExploreCard';
import './ExploreSection.scss';

const CardsMobile = (props) => {
  const { cardsData } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>
      <div className="slider">
        <Slider {...settings}>
          {cardsData?.map((card, index) => (
            <ExploreCard cardData={card} key={index} />
          ))}
        </Slider>
      </div>
    </>
  );
};

export default CardsMobile;
