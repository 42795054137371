import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import Heading from '../common/HeadingComponent';
import './MediaWithText.scss';

const PageInfoWithIcon = ({ data }) => {
  return (
    <div className="PageInfoWithIcon">
      {data?.Title?.value !== '' ? (
        // <Heading
        //   heading={data?.Title}
        //   alignText="left"
        //   className={'pageInfoTitle'}
        // />
        <Box className="pageInfo-Title">
          <Text field={data?.Title} />
        </Box>
      ) : (
        <></>
      )}
      <Box
        sx={{
          textAlign: data?.ImageAlign?.fields?.Text?.value
            ? data?.ImageAlign?.fields?.Text?.value
            : 'center'
        }}
        className="hidden-tablet">
        <Image field={data?.Image} />
      </Box>
    </div>
  );
};

PageInfoWithIcon.defaultProps = {
  data: {
    Image: {
      value: { src: '', alt: '' }
    },
    ImageCaption: {
      value: 'COMPLAINT RESOLUTION FOR YOU!'
    },
    Title: {
      value: 'Complaint Resolution'
    }
  }
};

PageInfoWithIcon.propTypes = {
  Image1: PropTypes.object
};

export default PageInfoWithIcon;
