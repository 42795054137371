import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Typography from '@mui/material/Typography';
import { Container, Grid, Box } from '@mui/material';
import expandicon from '../../assets/plus-expand.svg';
import collapseIcon from '../../assets/collapse-icon.svg';
import './Legalsection.scss';

const Legalsection = ({ fields }) => {
  const [expanded, setExpanded] = useState(1);
  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? 0 : 1);
  };
  return (
    <Box className="legal-wrapper" role="region" aria-label="legalsection">
      <Container>
        <Grid item sm={12} md={12} lg={12} className="Legal-Container">
          {fields?.Description?.value && (
            <Accordion
              className="Accordion-Main"
              expanded={expanded === 0}
              onChange={handleChange(expanded)}>
              <AccordionSummary
                sx={{
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'rotate(0deg)',
                    m: '10px'
                  }
                }}
                className="AccordionSummary-Texts"
                expandIcon={
                  // <img src={expandicon} alt="" loading="lazy" />
                  <img
                    src={expanded ? expandicon : collapseIcon}
                    alt=""
                    loading="lazy"
                    height={expanded ? 16 : 2}
                    width={16}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography className="Accordion-title-text">
                  <Text field={fields?.Title} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="Accordion-Details-text">
                  <RichText field={fields?.Description} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Legalsection;
