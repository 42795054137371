import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { withSearch } from '../../../contexts/search';
import './SearchResultHeader.scss';

export const SearchResultHeader = (props) => {
  const { searchData, searchKeyword, setIsSearchActive, dictionaryData } =
    props;
  const { clearAll = '', result = '' } = dictionaryData;

  return (
    <Grid container className="resultHeaderSection">
      <Grid item xs={12} className="resultRow">
        <Typography className="resultsCount">
          {searchData !== null && searchData.length} {result}
          {searchData !== null && searchData.length > 1 && 's'}
        </Typography>
        <Button
          variant="text"
          onClick={() => setIsSearchActive(false)}
          className="clearall">
          {clearAll}
        </Button>
      </Grid>
      {!!searchKeyword && (
        <Grid item xs={12}>
          <Typography className="searchWord">{`"${searchKeyword}"`}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default withSearch(SearchResultHeader);
