import { Button, Zoom, useScrollTrigger } from '@mui/material';
import React, { useState, useEffect } from 'react';
import UpArrow from '../../assets/UpArrow.svg';
import blueUpArrow from '../../assets/blue-vector.svg';
import './SubFooterComponent.scss';

const ScrollToTopButton = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <Zoom className="scroll-to-top-div" in={trigger}>
      <div onClick={handleClick} role="presentation">
        <Button
          className="ScrollTo-TopButton"
          aria-label={text}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <img
            src={isHovered ? blueUpArrow : UpArrow}
            loading="lazy"
            height={9}
            width={16}
            alt="ScrolltoTop"
          />
          {text}
        </Button>
      </div>
    </Zoom>
  );
};
export default ScrollToTopButton;
