const getBaseURL = () => {
  let baseURL;
  if (window && window.location.hostname === 'localhost') {
    baseURL = 'https://acg-cm.dev.local';
  } else if (window) {
    baseURL = window.location.origin;
  }
  return baseURL;
};

const getPaginationCount = (TotalResults, resultsPerPageNews) => {
  return TotalResults % resultsPerPageNews === 0
    ? TotalResults / resultsPerPageNews
    : Math.floor(TotalResults / resultsPerPageNews) + 1;
};

const handleAnchorClick = (e) => {
  e.preventDefault();
  const ele = e?.target;
  if (ele?.tagName === 'A') {
    const hrefVal = ele?.getAttribute('href')?.replace('#', '');
    const scrollTosection = document.getElementById(hrefVal);
    const headerHeight = document.getElementById(
      'navigation-container' // classname should be added from sitecore to all 'A' tags which needs to scroll on page
    )?.offsetHeight;

    window.scrollTo({
      top: scrollTosection?.offsetTop - headerHeight,
      behavior: 'smooth'
    });
  }
};

export { getBaseURL, getPaginationCount, handleAnchorClick };
