import React from 'react';
import { Container, Box } from '@mui/material';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import HeadingComponent from '../common/HeadingComponent';
import AllstateButton from '../common/Button';
import './acknowledgement.scss';

const AcknowledgementSection = (props) => {
  const { Description, Heading, Icon, Link1, Link2, Title } = props?.fields;
  return (
    <Container className="section-padding acknowledgement-section">
      <Image field={Icon} />
      <HeadingComponent className="acknowlegment-heading" heading={Heading} />
      <RichText className="acknowlegment-title" field={Title} />
      <RichText className="acknowlegment-desc" field={Description} />
      <Box className="button-wrapper">
        <AllstateButton
          className="border-orange button-styles"
          buttonTextclasses="button-text"
          variant="tertiary"
          size="medium"
          content={Link1}
        />
        <AllstateButton
          className="button-styles"
          buttonTextclasses="button-text"
          size="medium"
          content={Link2}
        />
      </Box>
    </Container>
  );
};

export default AcknowledgementSection;
