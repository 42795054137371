import React from 'react';
import './MainContent.scss';
import { Container, Grid, Box, Button } from '@mui/material';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import AllstateButton from '../common/Button';

export default function MainContent({ data }) {
  const renderList = (values) => {
    return (
      <ul className="Array-Liststyle">
        {values.map((item, i) => {
          if (i <= 5) {
            return (
              <li key={i}>
                <Link field={item?.fields?.Link} className="Arrays" />
              </li>
            );
          }
        })}
      </ul>
    );
  };
  return (
    <Container className="mobile-pad">
      <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid className="LeftColGrid" item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div>
            <div className="AllstateLogoAndTitle">
              <div>
                <Image field={data?.FooterImage} loading="lazy" />
              </div>
              <Box
                sx={{
                  pt: !data?.FooterImage?.value?.src && 2,
                  pb: !data?.FooterImage?.value?.src && 2
                }}
                className="allstatetitlepart">
                <a href="/" className="AllstateTitleText">
                  <RichText field={data?.FooterTitle} />
                </a>
              </Box>
            </div>
            <div className="myAllstateButtonSection">
              <AllstateButton
                size="medium"
                className="MyAllstatePortalButton"
                buttonTextclasses="MyAllstatePortalButtonText"
                content={data?.MyAllstateLink}
              />
            </div>
            <div className="MobileappsTitle">
              <RichText field={data?.MobileAppTitle} />
            </div>
            <div className="PlaySotreSection">
              <a
                href={data?.PlaystoreLink?.value?.href}
                target={data?.PlaystoreLink?.value?.target}>
                <img
                  src={data?.PlaystoreImage?.value?.src}
                  className="PlaystoreIcons"
                  alt={data?.PlaystoreImage?.value?.alt}
                  loading="lazy"
                  height={33}
                  width={112}
                />
              </a>
              <span>
                <a
                  href={data?.AppstoreLink?.value?.href}
                  target={data?.AppstoreLink?.value?.target}>
                  <img
                    src={data?.AppstoreImage?.value?.src}
                    className="PlaystoreIcons"
                    alt={data?.AppstoreImage?.value?.alt}
                    loading="lazy"
                    height={33}
                    width={99}
                  />
                </a>
              </span>
            </div>
          </div>
        </Grid>

        <Grid
          className="CompanyandlinksGrid"
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}>
          <Grid
            className="ColumnLeftRightPadding"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}>
            <div>
              <Box className="LinksTitleStyle">
                <Text field={data?.primaryFooterMenuLinks[0]?.fields?.Title} />
              </Box>
              {renderList(data?.primaryFooterMenuLinks[0]?.fields?.footerlinks)}
            </div>
          </Grid>
          <Grid
            className="ColumnLeftRightPadding"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}>
            <Box className="LinksTitleStyle">
              <Text field={data?.primaryFooterMenuLinks[1]?.fields?.Title} />
            </Box>
            {renderList(data?.primaryFooterMenuLinks[1]?.fields?.footerlinks)}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          className="ProductServicesGrid">
          <Box className="ProductandservicesTitle">
            <Text field={data?.primaryFooterMenuLinks[2]?.fields?.Title} />
            {/* <Box className="ScrollToTopButtonSection">
              <ScrollToTopButton text={data?.ScrollTopText?.value} />
            </Box> */}
          </Box>

          <div className="two-column-array">
            <Grid
              className="ColumnLeftRightPadding"
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}>
              {renderList(data?.primaryFooterMenuLinks[2]?.fields?.footerlinks)}
            </Grid>
            <Grid
              className="ColumnLeftRightPadding"
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}>
              {renderList(
                data?.primaryFooterMenuLinks[2]?.fields?.footerlinks.slice(6)
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
