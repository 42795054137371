import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Grid, Container, Box } from '@mui/material';
import { useWindowSize } from '../../utils/useWindowSize';
import Slider from 'react-slick';
import CardLayout from './card';
import './CardLayout.scss';

const CardComponent = ({ fields }) => {
  const [width] = useWindowSize();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const renderImageCardDesktop = (values) => {
    return (
      <>
        {values?.map((item, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              key={i}
              role="region"
              aria-label="cardsection">
              <CardLayout data={item?.fields} />
            </Grid>
          );
        })}
      </>
    );
  };
  const renderImageCardMobile = (values) => {
    return (
      <Slider {...settings}>
        {values?.map((item, i) => {
          return (
            <div key={i}>
              <CardLayout data={item?.fields} />
            </div>
          );
        })}
      </Slider>
    );
  };
  return (
    <Box className="cards-container">
      {fields?.Title?.value && (
        <p className="cc-title">
          <Text field={fields?.Title} />
        </p>
      )}
      {width > 1081 ? (
        <Container fluid>
          <Grid
            className="maingrid"
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
            {renderImageCardDesktop(fields?.CardList)}
          </Grid>
        </Container>
      ) : (
        <>
          <div className="Discover-slider">
            {renderImageCardMobile(fields?.CardList)}
          </div>
        </>
      )}
    </Box>
  );
};

export default CardComponent;
