import React, { useState, useEffect } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Heading from '../HeadingComponent';
import expandicon from '../../../assets/expandIcon.svg';
import './accordian.scss';
import HTagComponent from '../HTagComponent/HTagComponent';

const AllstateAccordion = (props) => {
  const {
    accordianData = null,
    accordionTitle = null,
    setFirstIndexOpen = false // use prop to open first index accordion on page load
  } = props;
  const singleItemClass = accordianData?.length === 1 ? 'single-accordian' : '';
  const expandIndex = setFirstIndexOpen ? 0 : null;
  const [expanded, setExpanded] = useState(expandIndex);
  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : null);
  };

  useEffect(() => {
    const element = document.getElementsByClassName('MuiAccordion-region');
    for (let i = 0; i < element.length; i++) {
      const element1 = document.getElementsByClassName('MuiAccordion-region')[
        i
      ];
      const attr = element1.getAttributeNode('role');
      if (attr !== null) {
        element1.removeAttributeNode(attr);
      }
    }
  }, [accordianData]);

  return (
    <div className="AccordionContainer">
      {accordionTitle?.value !== '' ? (
        accordionTitle && <Heading alignText="left" heading={accordionTitle} />
      ) : (
        <></>
      )}
      {accordianData?.map((item, index) => (
        <Accordion
          className={`AccordionMain ${singleItemClass}`}
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}>
          <AccordionSummary
            expandIcon={
              <img
                src={expandicon}
                alt=""
                loading="lazy"
                height={10}
                width={16}
              />
            }>
            <Typography className="AccordionSummaryText">
              {item?.fields?.QuestionHTag?.value !== '' &&
              item?.fields?.QuestionHTag ? (
                <HTagComponent
                  TitleText={item?.fields?.Question || item?.fields?.Title}
                  hTag={item?.fields?.QuestionHTag}
                />
              ) : (
                <Text field={item?.fields?.Question || item?.fields?.Title} />
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="AccordionDetailsText">
              <RichText
                className="rich-text-desc"
                field={item?.fields?.Text || item?.fields?.Description}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AllstateAccordion;
