import React, { useState } from 'react';
import { SearchContext } from './searchContext';

const SearchProvider = ({ children }) => {
  const [searchData, setSearchData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [dictionaryData, setDictionaryData] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);

  return (
    <SearchContext.Provider
      value={{
        searchData,
        setSearchData,
        searchKeyword,
        setSearchKeyword,
        loading,
        setLoading,
        hasError,
        setHasError,
        errorData,
        setErrorData,
        dictionaryData,
        setDictionaryData,
        isSearchActive,
        setIsSearchActive
      }}>
      {children}
    </SearchContext.Provider>
  );
};
export default SearchProvider;
