import React from 'react';
import Box from '@mui/material/Box';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import RightBulleticon from '../../../assets/RightBulleticon.svg';
import './BulletPoint.scss';

const BulletPoint = ({ content, bulletStyle }) => {
  return (
    <>
      {content.length > 0 && (
        <ul className="BulletList">
          {content?.map((data, i) => {
            const { Description, Title } = data?.fields;
            return (
              <li key={i} className={`content-list ${bulletStyle}`}>
                <div className="bullet-point-icon">
                  <img
                    src={RightBulleticon}
                    alt=""
                    loading="lazy"
                    height={14}
                    width={18}
                  />
                </div>
                <div className="content-list-title">
                  {Title && <Text field={Title} />}
                  {Description && (
                    // <RichText
                    //   tag="span"
                    //   className="content-Description"
                    //   field={Description}
                    // />
                    <RichText
                      tag="span"
                      className={
                        Title?.value
                          ? 'content-Description'
                          : 'content-Description-alone'
                      }
                      field={Description}
                    />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default BulletPoint;
