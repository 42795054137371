import React from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import './index.scss';
import Left_Icon from './assests/Left_Icon.svg';
import Right_Icon from './assests/Right_Icon.svg';
import { useWindowSize } from '../../utils/useWindowSize';
import { Container, Box } from '@mui/material';

export function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img
      alt="next-arrow"
      src={Right_Icon}
      className={`${className} discount-next-arrow`}
      onClick={onClick}
      id="next-arrow"
      loading="lazy"
    />
  );
}
export function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img
      alt="prev-arrow"
      src={Left_Icon}
      className={`${className} discount-previous-arrow`}
      onClick={onClick}
      id="prev-arrow"
      loading="lazy"
    />
  );
}

// const getSliderSettings = (isMobile) => {
//   const settings = {
//     infinite: false,
//     speed: 500,
//     slidesToShow: isMobile ? 1 : 3,
//     slidesToScroll: isMobile ? 1 : 3,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />
//   };

//   return settings;
// };

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

const _VARIANT = 'noscroll';

const DiscountSection = (props) => {
  const {
    fields: {
      DiscountSections,
      Title,
      ShortDescritpion,
      DiscountSectionType: {
        fields: {
          Text: { value: VARIANT }
        }
      }
    }
  } = props;

  const size = useWindowSize();
  // const isMobile = size <= 768;
  return (
    <Box
      role="region"
      aria-label="discount section"
      className={`discount-section-container ${
        VARIANT === 'noscroll' && 'noscroll'
      }`}>
      <Container>
        <div className="discount-section-text">
          <h2 className="discount-section-heading">
            <RichText field={Title} />
          </h2>
          <p className="discount-section-content">
            <RichText field={ShortDescritpion} />
          </p>
        </div>
        {VARIANT !== 'noscroll' ? (
          <div className="slider-container discount-slider">
            <Slider className="Discount-Slider-Align" {...settings}>
              {DiscountSections?.map((discountSection, index) => (
                <Card
                  key={discountSection}
                  className={`card-content ${
                    index === DiscountSections?.length - 1 ? 'last-item' : ''
                  }`}>
                  <div className="card-image">
                    <Image
                      field={discountSection?.fields?.Image}
                      loading="lazy"
                    />
                  </div>
                  <CardContent>
                    <Typography className="card-header">
                      <Text field={discountSection?.fields?.ImageCaption} />
                    </Typography>
                    <Typography className="card-description">
                      <RichText field={discountSection?.fields?.Text} />
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Slider>
          </div>
        ) : (
          <div
            className={`discount-slider ${
              VARIANT === 'noscroll' && 'noscroll'
            }`}>
            {DiscountSections?.map((discountSection, index) => (
              <>
                <Card
                  key={discountSection}
                  className={`card-content ${
                    index === DiscountSections?.length - 1 ? 'last-item' : ''
                  }`}>
                  <div className="card-image">
                    <Image
                      field={discountSection?.fields?.Image}
                      loading="lazy"
                    />
                  </div>
                  <CardContent>
                    <Typography className="card-description">
                      <Text field={discountSection?.fields?.ImageCaption} />
                    </Typography>
                    <Typography className="card-header">
                      <RichText field={discountSection?.fields?.Text} />
                    </Typography>
                  </CardContent>
                </Card>
                {index !== DiscountSections?.length - 1 && (
                  <div className="seperator"></div>
                )}
              </>
            ))}
          </div>
        )}
      </Container>
    </Box>
  );
};
export default DiscountSection;
