import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Image, Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import { Box } from '@mui/material';
import './imageMediaCard.scss';
import BulletPoint from './BulletPoint/BulletPoint';

export default function ImgMediaCard({ data }) {
  return (
    <Card className="maincard">
      <Box className="outbox">
        <Image field={data?.Image} loading="lazy" />
        <Box className="innerbox">
          <p className="imagetitle">
            <Text field={data?.ImageCaption} />
          </p>
        </Box>
      </Box>
      <CardContent className="CardContents">
        <BulletPoint content={data?.ListItem} bulletStyle={'cardcontent'} />
      </CardContent>
      <CardActions>
        <button className="buttonlink">
          <Link tabIndex={-1} field={data?.TargetUrl} />
          <i className="arrow right"></i>
        </button>
      </CardActions>
    </Card>
  );
}
