import React, { useState, useLayoutEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '@mui/material';
import LevelTwoCard from './LevelTwoRichTextCard';
import AllstateButton from '../common/Button';
import Slider from 'react-slick';
import { useWindowSize } from '../../utils/useWindowSize';
import './DiscoverTwo.scss';

const DiscoverOtherWays = ({ fields }) => {
  const [width] = useWindowSize();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const renderImageCardDesktop = (values) => {
    return (
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        className="gridclass"
        justifyContent={'center'}
        alignItems={'center'}>
        {values?.map((item, i) => {
          return (
            <div key={i}>
              <LevelTwoCard data={item?.fields} />
            </div>
          );
        })}
      </Grid>
    );
  };
  const renderImageCardMobile = (values) => {
    return (
      <Slider {...settings}>
        {values?.map((item, i) => {
          return (
            <div key={i}>
              <LevelTwoCard data={item?.fields} />
            </div>
          );
        })}
      </Slider>
    );
  };
  return (
    <div className="cardwrapper">
      {width > 1081 ? (
        <>
          <Box>
            <Typography
              variant="caption"
              component="p"
              className="discoverSectionCaption">
              <Text field={fields?.ExploreSectionLabel} />
            </Typography>
            <Typography
              variant="subtitle"
              component="p"
              className="discoverSectionSubtitle">
              <Text field={fields?.ExploreSectionHeading} />
            </Typography>
          </Box>
          {renderImageCardDesktop(fields?.ExploreCategories)}
        </>
      ) : (
        <>
          <div className="slidercard">
            <Box>
              <Typography
                variant="caption"
                component="p"
                className="discoverSectionCaption">
                <Text field={fields?.ExploreSectionLabel} />
              </Typography>
              <Typography
                variant="subtitle"
                component="p"
                className="discoverSectionSubtitle">
                <Text field={fields?.ExploreSectionHeading} />
              </Typography>
            </Box>
            {renderImageCardMobile(fields?.ExploreCategories)}
          </div>
        </>
      )}
      <div className="findAgentBtn">
        <AllstateButton
          content={fields?.ExploreAllButton}
          variant="exploreMoreBtn"
          className="find-agent-btn"
        />
      </div>
    </div>
  );
};

export default DiscoverOtherWays;
