import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, FormGroup, TextField } from '@mui/material';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withSearch } from '../../../contexts/search';
import getDictionaryData from '../../../utils/getDictionaryData';
import './SearchBox.scss';

const API_END_POINT = '/api/Sitecore/Search/GetFAQSearchResults';

const SearchBox = (props) => {
  const {
    fields: { ButtonText },
    setSearchData,
    setSearchKeyword,
    setLoading,
    setHasError,
    setErrorData,
    dictionaryData,
    setDictionaryData,
    isSearchActive,
    setIsSearchActive,
    context
  } = props;

  const [keyword, setKeyword] = useState('');
  const [dafaultSearchData, setDefaultSearchData] = useState(null);

  useEffect(() => {
    if (!isSearchActive) {
      setKeyword('');
      resetToDafault();
    }
  }, [isSearchActive]);

  useEffect(() => {
    if (keyword.length === 0) {
      resetToDafault();
    }
  }, [keyword]);

  const resetToDafault = () => {
    setHasError(false);
    setErrorData(null);
    setSearchKeyword('');
    setSearchData(dafaultSearchData);
  };

  const _getAPIURL = () => {
    // const pathUrl = window.location.href;
    // const lastIndex = pathUrl.lastIndexOf('/');
    // const baseUrl = pathUrl.slice(0, lastIndex);

    const baseUrl = window.location.origin;

    let API_BASE_URL = baseUrl;
    if (baseUrl.search('localhost') !== -1) {
      API_BASE_URL = 'https://acg-cm.dev.local';
    }

    return { API_BASE_URL, API_END_POINT };
  };

  const _getFaqData = (shouldFetchAll = false) => {
    setLoading(true);
    setHasError(false);
    const { API_BASE_URL, API_END_POINT } = _getAPIURL();
    const API_URL = API_BASE_URL + API_END_POINT;
    let config = {
      headers: {},
      params: { query: keyword }
    };
    axios
      .get(API_URL, config)
      .then((response) => {
        setLoading(false);
        setSearchData(response?.data);
        if (!response.data.length) {
          // No record found
          setHasError(true);
          setErrorData({ value: dictionaryData?.noResult });
        }
        if (shouldFetchAll) {
          setDefaultSearchData(response.data);
        }
      })
      .catch((error) => {
        if (keyword.length) {
          // No record found
          setHasError(true);
          setErrorData({ value: dictionaryData?.noResult });
        }
        setSearchData([]);
        setLoading(false);
      });
  };

  /**
   * Developer Note: Dictionary data
   * Fetch and store dictionary data in search context when component mount.
   */
  const _getDictionaryData = async () => {
    const dictionaryData = await getDictionaryData(context);
    setDictionaryData(dictionaryData);
  };

  useEffect(() => {
    _getDictionaryData();
    _getFaqData(true);

    return () => {
      setSearchData(null);
      setSearchKeyword('');
    };
  }, []);

  const handleSearch = () => {
    if (keyword.length > 2) {
      _getFaqData();
    } else {
      setHasError(true);
      setErrorData({ value: dictionaryData?.noResultEnterLeastCharacter });
      setSearchData([]);
    }
    setIsSearchActive(true);
    setSearchKeyword(keyword);
  };
  const handelkeypress = (e) => {
    if (e.code === 'Enter') {
      handleSearch();
    }
  };

  return (
    <FormGroup row className="SearchBox">
      <TextField
        variant="outlined"
        placeholder={dictionaryData?.search_keyword}
        className="searchTextInput"
        value={keyword}
        onKeyDown={(e) => handelkeypress(e)}
        onChange={(event) => {
          setKeyword(event.target.value);
        }}
        inputProps={{ maxLength: 50 }}
      />
      <Button
        variant="contained"
        disableElevation
        className="searchButton"
        onClick={() => handleSearch()}>
        <Text field={ButtonText} />
      </Button>
    </FormGroup>
  );
};

export default withSearch(SearchBox);
export { SearchBox };
