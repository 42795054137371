import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './RichTextComponent.scss';

const RichTextComponent = (props) => {
  const { content = '', richTextClasses = '', ...rest } = props;
  return (
    <Typography className={`RichTextComponent ${richTextClasses}`} {...rest}>
      <RichText field={content} />
    </Typography>
  );
};

RichTextComponent.propTypes = {
  content: PropTypes.object.isRequired,
  richTextClasses: PropTypes.string
};

export default RichTextComponent;
