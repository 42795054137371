import React from 'react';
import { Grid } from '@mui/material';
import ExploreCard from './ExploreCard';

const CardsDesktop = (props) => {
  const { cardsData } = props;
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
        {cardsData?.map((card, index) => (
          <ExploreCard cardData={card} key={index} />
        ))}
      </Grid>
    </>
  );
};

export default CardsDesktop;
