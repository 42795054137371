import React, { useState, useLayoutEffect } from 'react';
import ImgMediaCard from '../common/ImgMediaCard';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Grid, Container } from '@mui/material';
import './discoversection.scss';
import { useWindowSize } from '../../utils/useWindowSize';
import Slider from 'react-slick';

const DiscoverSection = ({ fields }) => {
  const [width] = useWindowSize();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const renderImageCardDesktop = (values) => {
    return (
      <>
        {values?.map((item, i) => {
          return (
            <Grid item xs={12} sm={4} md={4} lg={4} key={i}>
              <ImgMediaCard data={item?.fields} />
            </Grid>
          );
        })}
      </>
    );
  };
  const renderImageCardMobile = (values) => {
    return (
      <Slider {...settings}>
        {values?.map((item, i) => {
          return (
            <div key={i}>
              <ImgMediaCard data={item?.fields} />
            </div>
          );
        })}
      </Slider>
    );
  };
  return (
    <Container>
      <div className="section-padding discoverContainer">
        <p className="pheader">
          <Text field={fields?.Title} />
        </p>
        {width > 1081 ? (
          <Container fluid>
            <Grid
              className="maingrid"
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
              {renderImageCardDesktop(fields?.Section)}
            </Grid>
          </Container>
        ) : (
          <>
            <div className="Discover-slider">
              {renderImageCardMobile(fields?.Section)}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default DiscoverSection;
