import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Container,
  Box,
  Paper,
  OutlinedInput,
  Popper,
  Typography,
  ClickAwayListener
} from '@mui/material';
import AllstateButton from '../common/Button';
import Close_Icon from '../../assets/remove_close.svg';
import Dropdownarrow from '../../assets/down-arrow.svg';
import signinIconBlack from '../../assets/signin-iconBlack.svg';
import SelectedMenu from './TopNavStripComponents/SelectedMenu';
import Heading from '../common/HeadingComponent';
import { withTranslation } from 'react-i18next';
import AllStateAbout from '../AllStateAbout';
import './header.scss';
import {
  specialChars,
  noResult,
  noResultEnterLeastCharacter,
  searchSessionQuery,
  search_keyword,
  LangEN,
  LangFR,
  geoUrl
} from '../constants';
import { getBaseURL } from '../../utils';

export const handleLang = (val, language, langList) => {
  if (
    val === 'French' ||
    val === 'FR' ||
    val === 'English' ||
    val === 'EN' ||
    val === 'Français' ||
    val === 'Anglais'
  ) {
    const value =
      val === 'French' || val === 'FR' || val === 'Français'
        ? LangFR.toLowerCase()
        : LangEN.toLowerCase();

    if (value !== language.toLowerCase()) {
      if (value.toLowerCase() === 'en-ca') {
        const currentURL = window.location.href?.toLowerCase();
        const frPresent = currentURL?.includes('fr-ca');
        if (frPresent) {
          const Present = currentURL?.includes('fr-ca/');
          if (Present) {
            const newURL = currentURL?.replace('fr-ca/', '');
            document.cookie = `allstateca#lang=${value};path=/`;
            sessionStorage.setItem('selectLang', value);
            window.open(newURL, '_self');
          } else {
            const newURL = currentURL?.replace('fr-ca', '');
            document.cookie = `allstateca#lang=${value};path=/`;
            sessionStorage.setItem('selectLang', value);
            window.open(newURL, '_self');
          }
        } else {
          document.cookie = `allstateca#lang=${value};path=/`;
          sessionStorage.setItem('selectLang', value);
          window.open(window.location.href, '_self');
        }
      }
      if (value.toLowerCase() === 'fr-ca') {
        const newURL = window.location.href?.replace(
          window.location.origin,
          ''
        );
        window.open(`${window.location.origin}/${value}${newURL}`, '_self');
      }
    }
    // document.cookie = `allstateca#lang=${value};path=/`;
    // sessionStorage.setItem('selectLang', value);
    // window.open(window.location.href, '_self');
    // const engPresent = window.location.href?.includes('en-CA');
    // const frPresent = window.location.href?.includes('fr-CA');
    // const sengPresent = window.location.href?.includes('en-ca');
    // const sfrPresent = window.location.href?.includes('fr-ca');
    // if (value !== language && engPresent) {
    //   const newURL = window.location.href?.replace('en-CA', 'fr-CA');
    //   window.open(`${newURL}`, '_self');
    // } else if (value !== language && frPresent) {
    //   const newURL = window.location.href?.replace('fr-CA', 'en-CA');
    //   window.open(`${newURL}`, '_self');
    // } else if (value !== language.toLowerCase() && sfrPresent) {
    //   const newURL = window.location.href?.replace('fr-ca', 'en-ca');
    //   window.open(`${newURL}`, '_self');
    // } else if (value !== language.toLowerCase() && sengPresent) {
    //   const newURL = window.location.href?.replace('en-ca', 'fr-ca');
    //   window.open(`${newURL}`, '_self');
    // } else if (
    //   window.location.origin !== window.location.href &&
    //   !engPresent &&
    //   !frPresent &&
    //   !sfrPresent &&
    //   !sengPresent
    // ) {
    //   const newURL = window.location.href?.replace(window.location.origin, '');
    //   window.open(`${window.location.origin}/${value}${newURL}`, '_self');
    // }
  } else {
    window.open(langList[2]?.fields?.Link?.value?.href, '_blank');
  }
};

const TopNavStrip = (props) => {
  const { topHeaderMenuLinks, language, t, geoLocation } = props;
  const {
    allStateSiteslstNew,
    getQuote,
    searchText,
    geoLocationlst,
    languagelst,
    signIn,
    searchIcon,
    geoIcon,
    languageIcon,
    searchPage,
    searchHeading,
    signinIcon
  } = topHeaderMenuLinks[0]?.fields;

  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  const open = Boolean(anchorEl);
  const id = open ? 'search-popup' : undefined;

  const { Image, ImageCaption } = allStateSiteslstNew?.fields?.Icon?.fields;

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(false);
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [searchopenCounter, setSearchOpenCounter] = useState(0);

  const fetchData = async (geoLocation) => {
    const baseURL = getBaseURL();
    try {
      const data = await fetch(`${baseURL}${geoUrl}?state=${geoLocation}`);
      const json = await data.json();
      if (json.FetchData) {
        window.open(`${window.location.href}`, '_self');
      }
    } catch {
      console.log('error, something went wrong');
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget || null);
  };

  const resetState = () => {
    setError(false);
    setSearchValue('');
  };

  const handleClickSearch = (event) => {
    const Present = window.location.href?.includes(searchPage?.value?.href);
    if (!Present) {
      resetState();
      setAnchorElSearch(event?.currentTarget || null);
      if (event?.currentTarget) {
        inputRef?.current?.focus();
      }
    } else {
      resetState();
      setAnchorElSearch(null);
      document.getElementById('globalsearch-input').focus();
    }
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' && validateInput(e)) {
      window.open(
        `${window.location.origin}${searchPage?.value?.href}?q=${searchValue}`,
        '_self'
      );
    }
  };

  const handelredirect = () => {
    window.open(
      `${window.location.origin}${searchPage?.value?.href}?q=${searchValue}`,
      '_self'
    );
  };

  const validateInput = (e) => {
    if (!specialChars.test(searchValue)) {
      e.preventDefault();
      setError(true);
      return false;
    } else if (searchValue?.length < 3) {
      e.preventDefault();
      setError(true);
      return false;
    } else {
      setError(false);
      sessionStorage.setItem(searchSessionQuery, searchValue);
      handelredirect();
      return true;
    }
  };

  const returnlocation = (geoLocation) => {
    switch (geoLocation) {
      case 'Alberta':
        return 'AB';
      case 'Ontario':
        return 'ON';
      case 'Quebec':
        return 'QC';
      case 'New Brunswick':
        return 'NB';
      case 'Nova Scotia':
        return 'NS';
      case 'Québec':
        return 'QC';
      case 'Nouveau-Brunswick':
        return 'NB';
      case 'Nouvelle-É cosse':
        return 'NS';
      default:
        return 'AB';
    }
  };

  const searchButtonText = { value: { text: searchPage?.value?.text } };

  const handleGeo = (val) => {
    // Todo: Once sitecore team will provide details about it.
    fetchData(returnlocation(val));
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box className="top-nav-strip hidden-mobile">
      <Container>
        <Grid container>
          <Grid item xs={6} md={6} lg={4}>
            <AllstateButton
              content={ImageCaption}
              variant="tertiary"
              isLink={false}
              size="small"
              startIcon={
                <img
                  src={Image?.value?.src}
                  alt={Image?.value?.alt}
                  loading="lazy"
                  height={13}
                  width={26}
                />
              }
              endIcon={
                <img
                  src={Dropdownarrow}
                  alt="dropdown-arrow"
                  loading="lazy"
                  height={5}
                  width={9}
                />
              }
              className="acaMyAllStateBtn"
              lebel={ImageCaption?.value}
              onClick={(e) => handleClick(e)}
            />

            <AllStateAbout
              handleClick={handleClick}
              anchorEl={anchorEl}
              {...allStateSiteslstNew}
            />

            <AllstateButton
              content={getQuote}
              lebel={getQuote?.value?.text}
              variant="primary"
              size="small"
              sx={{ m: '7px 0 0', p: '4px 16px' }}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={8} className="align-reverse">
            <AllstateButton
              content={searchText}
              isLink={false}
              variant="tertiary"
              size="small"
              startIcon={
                <img
                  src={searchIcon?.value?.src}
                  alt="search-icon"
                  loading="lazy"
                  height={19}
                  width={20}
                />
              }
              className="acaMyAllStateBtn"
              onClick={(e) => handleClickSearch(e)}
              lebel={searchText?.value}
            />
            <ClickAwayListener
              onClickAway={() => {
                if (Boolean(anchorElSearch) && searchopenCounter === 1) {
                  setSearchOpenCounter(0);
                  handleClickSearch(null);
                } else if (Boolean(anchorElSearch) && searchopenCounter === 0) {
                  setSearchOpenCounter(1);
                  inputRef?.current?.focus();
                }
              }}>
              <Popper
                open={Boolean(anchorElSearch)}
                keepMounted
                id={id}
                className="search-popper"
                anchorEl={anchorElSearch}
                placement={'bottom-start'}>
                <img
                  alt="close-icon"
                  src={Close_Icon}
                  className="close-popper"
                  onClick={() => {
                    if (Boolean(anchorElSearch) && searchopenCounter === 1) {
                      setSearchOpenCounter(0);
                      handleClickSearch(null);
                    }
                  }}
                  id="close-icon"
                  loading="lazy"
                  height={13}
                  width={13}
                />
                <Paper className="search-wrapper">
                  <Box className={'search-header'}>
                    <Heading
                      className="search-heading-wrapper"
                      components={{ headingTag: 'h6' }}
                      heading={searchHeading}
                    />
                  </Box>
                  <OutlinedInput
                    value={searchValue}
                    inputRef={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder="search"
                    onChange={(e) => {
                      onSearchChange(e);
                    }}
                    error={error}
                    id="search"
                    className="search-input-bar"
                    inputProps={{
                      autoFocus: true,
                      placeholder: t(search_keyword),
                      maxLength: 50
                    }}
                    endAdornment={
                      <AllstateButton
                        onClick={(e) => validateInput(e)}
                        variant="search"
                        className="search-input-button"
                        content={searchButtonText}
                      />
                    }
                  />
                  {error && (
                    <Typography className="error-text">
                      {t(noResultEnterLeastCharacter)}
                    </Typography>
                  )}
                </Paper>
              </Popper>
            </ClickAwayListener>
            {geoLocationlst?.length > 0 && (
              <SelectedMenu
                handleSelect={(val) => handleGeo(val)}
                options={geoLocationlst}
                startIcon={geoIcon?.value?.src}
                altText="location-icon"
                keyDrop="Geo"
                geoLocation={geoLocation}
              />
            )}
            {languagelst?.length > 0 &&
              language !== '' &&
              language !== undefined &&
              languageIcon?.value?.src !== '' && (
                <SelectedMenu
                  handleSelect={(val) => handleLang(val, language, languagelst)}
                  options={languagelst}
                  startIcon={languageIcon?.value?.src}
                  altText="language-icon"
                  keyDrop="Lang"
                  language={language}
                />
              )}
            <span ref={buttonRef}>
              <AllstateButton
                content={signIn}
                variant="secondary"
                size="small"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                startIcon={
                  <img
                    src={isHovered ? signinIconBlack : signinIcon?.value?.src}
                    alt="account-icon"
                    loading="lazy"
                    height={18}
                    width={18}
                  />
                }
                className="acaMyAllStateBtnsignin"
                buttonTextclasses="acaMyAllStateBtnTxt"
                lebel={signinIcon?.value?.alt}
              />
            </span>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTranslation()(TopNavStrip);
