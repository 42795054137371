import React from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import TabPanel from '../common/TabPanel';
import { useWindowSize } from '../../utils/useWindowSize';
import AllstateTabs from '../common/TabComponent';
import LinearLayout from '../common/LinearLayout';
import Heading from '../common/HeadingComponent';
import { withSearch } from '../../contexts/search';
import getFAQData from './faqData';
import './FAQTabs.scss';

const FAQTabs = ({ fields, searchData, searchKeyword }) => {
  const { Caption = null, Title = null, List = [] } = fields;
  const [width] = useWindowSize();
  const [cardvalue, setShowCard] = React.useState(0);
  const [tabList, setTabList] = React.useState([]);
  const [tabContent, setTabContent] = React.useState(List);

  React.useEffect(() => {
    if (searchData !== null || !!searchKeyword.length) {
      setTabContent(getFAQData(searchData, List));
    }
  }, [searchData, searchKeyword]);

  React.useEffect(() => {
    // Developer Note: Re-create object as per AllstateTabs component required.
    const _list = fields?.List?.map((val) => {
      return {
        fields: {
          Image: val.fields.Image,
          ImageCaption: val.fields.TabTitle,
          HoverImage: val.fields.HoverImage
        }
      };
    });
    setTabList(_list);
  }, []);

  const handleChangeCallback = (cardValue) => {
    setShowCard(cardValue);
  };

  const fitTabtoScreen = tabList?.length < 4;
  const tabVariant = width > 600 || fitTabtoScreen ? 'fullWidth' : 'scrollable';
  const a11yProps = (index) => {
    return {
      id: `explore-tab-${index}`,
      'aria-controls': `explore-tabpanel-${index}`
    };
  };
  // const mt = fitTabtoScreen ? { xs: 2, md: 4 } : { xs: -6, md: -10 };
  // to change based on flag and sx to be removed
  return (
    <Box className="faq-page-tabcontent" role="region" aria-label="FaqTab">
      <Box
        className="explore-wrapper section-padding"
        sx={{
          p: { xs: 2, md: 6 },
          mx: { xs: 1, md: 4 },
          mt: { xs: 2, md: 4 },
          position: 'relative',
          borderRadius: 1
        }}>
        <Container className="mobile-padzero">
          {Title && <Heading caption={Caption} heading={Title} />}
          {tabList?.length > 0 && (
            <AllstateTabs
              fitTabtoScreen={fitTabtoScreen}
              tabVariant={tabVariant}
              tabCategories={tabList}
              tabButtona11yProps={a11yProps}
              tabButtonClassName={'exploreSectionTab'}
              tabClassName={'exploreSectionTabs'}
              scrollButtons={false}
              aria-label="explore options tab"
              handleChangeCallback={handleChangeCallback}
            />
          )}
        </Container>
      </Box>

      {tabContent?.map((tab, index) => {
        const { ImageCaption, TargetUrl, Text, AccordionList, Title } =
          tab?.fields;
        return (
          <TabPanel
            key={`exploresection-tabPanel ${index}`}
            value={cardvalue}
            index={index}>
            <LinearLayout
              AccordionList={AccordionList}
              title={ImageCaption}
              buttonDetails={TargetUrl}
              content={Text}
              classes="FAQtabContent"
              accordionTitle={Title}
              isFAQ={true}
            />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default withSearch(FAQTabs);
