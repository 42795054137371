import React from 'react';
import Box from '@mui/material/Box';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ListCollectionLayout from '../common/ListCollectionLayout/index';
import './ListCollectionComponent.scss';
import { Container } from '@mui/material';

const ListCollectionComponent = ({ fields }) => {
  return (
    <Box className="PageTopSection">
      <Container className="pagetopSection-container">
        {fields?.AllLists?.map((data, index) => {
          const {
            LeftColText,
            LeftColLink,
            ListItem,
            ListImage,
            Description,
            Title,
            BottomListText,
            ListImageMobile,
            LeftColTextHTag,
            TitleHTag
          } = data?.fields;
          return (
            <ListCollectionLayout
              key={`${index}`}
              lefttitle={LeftColText}
              leftbuttonDetails={LeftColLink}
              Title={Title}
              ListImage={ListImage}
              Description={Description}
              content={ListItem}
              BottomListText={BottomListText}
              classes="tabContent"
              ListImageMobile={ListImageMobile}
              LeftColTextHTag={LeftColTextHTag}
              TitleHTag={TitleHTag}
            />
          );
        })}
      </Container>
    </Box>
  );
};

export default ListCollectionComponent;
