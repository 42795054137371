import React, { useEffect } from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Routes, useParams } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';
import { createTheme, ThemeProvider } from '@mui/material';
import { GlobalDebug } from './utils/remove-consoles';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
const LANGUAGE_REG_EXP = /^\/?(([a-z]{2}-[A-Z]{2})|([a-z]{2}))(\/|$)/i;

export const parseRouteParams = (url) => {
  const language = url.match(LANGUAGE_REG_EXP);
  const route = url.replace(LANGUAGE_REG_EXP, '');
  return {
    route: route.startsWith('/') ? route : `/${route}`,
    language: language
      ? language[0].toLowerCase().replace(/\//g, '')
      : undefined
  };
};

const JssRoute = (props) => {
  useEffect(() => {
    GlobalDebug(false, true);
  }, []);

  useEffect(() => {
    const value = parseRouteParams(url);
    const lang = value?.language;
    const currentURL = window.location.href?.toLowerCase();
    const engPresent = currentURL?.includes('en-ca');
    if (engPresent) {
      const Present = currentURL?.includes('en-ca/');
      if (Present) {
        const newURL = currentURL?.replace('en-ca/', '');
        window.open(newURL, '_self');
      } else {
        const newURL = currentURL?.replace('en-ca', '');
        window.open(newURL, '_self');
      }
    }
  }, []);
  const params = useParams();
  const url = params['*'];

  return (
    <RouteHandler {...parseRouteParams(url)} url={url} isSSR={props.isSSR} />
  );
};
// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
// Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  render() {
    const { path, Router, graphQLClient } = this.props;
    const theme = createTheme({
      components: {
        MuiButtonBase: {
          defaultProps: {
            // The props to apply
            disableRipple: true // No more ripple, on the whole application 💣!
          }
        }
      }
    });

    return (
      <ApolloProvider client={graphQLClient}>
        <SitecoreContext
          componentFactory={componentFactory}
          layoutData={this.props.ssrState}>
          <ThemeProvider theme={theme}>
            <Router location={path} context={{}}>
              <Routes>
                <Route
                  path="*"
                  element={<JssRoute isSSR={!!this.props.ssrState} />}
                />
              </Routes>
            </Router>
          </ThemeProvider>
        </SitecoreContext>
      </ApolloProvider>
    );
  }
}

export default AppRoot;
