import React, { useState } from 'react';
import { Button, Menu, Typography, Divider } from '@mui/material';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Dropdownarrow from '../../assets/down-arrow.svg';

const MenuListItem = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // const linkOpen = (fields) => {
  //   window.open(fields?.Link?.value?.href);
  // };
  const { fields = {} } = props?.primaryMenuLink;
  const { menuSection = null } = fields;
  const isMultilevelMenu = fields?.menuSection?.length > 0;
  return (
    <>
      {/* <Button
        // id="basic-button"
        tabIndex={0}
        id={fields?.menuText?.value}
        className="menu-btn"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => isMultilevelMenu && handleClick(e)}
        endIcon={
          isMultilevelMenu ? (
            <img
              src={Dropdownarrow}
              alt="account-icon"
              loading="lazy"
              height={5}
              width={9}
            />
          ) : (
            ''
          )
        }>
        {fields?.menuText?.value ? (
          <Typography className="menu-links">
            <Text field={fields?.menuText} />
          </Typography>
        ) : (
          <Typography className="menu-links">
            <Link tabIndex={-1} field={fields?.Link} />
          </Typography>
        )}
      </Button> */}
      {fields?.menuText?.value ? (
        <Button
          // id="basic-button"
          tabIndex={0}
          id={fields?.menuText?.value}
          className="menu-btn"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => isMultilevelMenu && handleClick(e)}
          endIcon={
            isMultilevelMenu ? (
              <img
                src={Dropdownarrow}
                alt="account-icon"
                loading="lazy"
                height={5}
                width={9}
              />
            ) : (
              ''
            )
          }>
          <Typography className="menu-links">
            <Text field={fields?.menuText} />
          </Typography>
        </Button>
      ) : (
        <Button
          // id="basic-button"
          tabIndex={0}
          id={fields?.menuText?.value}
          className="menu-btn"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          // onClick={() => linkOpen(fields)}
          href={fields?.Link?.value?.href}
          target={fields?.Link?.value?.target}
          endIcon={
            isMultilevelMenu ? (
              <img
                src={Dropdownarrow}
                alt="account-icon"
                loading="lazy"
                height={5}
                width={9}
              />
            ) : (
              ''
            )
          }>
          <Typography className="menu-links">
            {fields?.Link?.value?.text}
          </Typography>
        </Button>
      )}
      {props?.showDivider && (
        <Divider
          className="divider"
          orientation="vertical"
          variant="middle"
          flexItem
        />
      )}
      {menuSection && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}>
          {props.renderDropdownitems(menuSection)}
        </Menu>
      )}
    </>
  );
};

export default MenuListItem;
