import React from 'react';
import { SearchContext } from './searchContext';

const withSearch = (Child) => {
  const MySearchComp = (props) => {
    return (
      <SearchContext.Consumer>
        {(context) => <Child {...props} {...context} />}
      </SearchContext.Consumer>
    );
  };
  return MySearchComp;
};

export default withSearch;
