import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { withAlert } from '../../contexts/alert';
import './index.scss';

const AlertBanner = (props) => {
  const {
    fields: { AlertText, Icon, IsAlertVisible, AlertColor, FontColor },
    setHasAlert
  } = props;

  const CloseIcon = () => {
    const closeIcon = Icon?.[1]?.fields?.Image;
    return <Image field={closeIcon} loading="lazy" height={13} width={13} />;
  };
  const WarningIcon = () => {
    const warningIcon = Icon?.[0]?.fields?.Image;
    return (
      <Image
        field={warningIcon}
        className="warning-margin"
        loading="lazy"
        height={24}
        width={24}
      />
    );
  };
  const theme = createTheme({
    components: {
      // Name of the component
      MuiAlert: {
        styleOverrides: {
          standardError: {
            backgroundColor: AlertColor?.fields?.Text?.value || '#CF120D',
            color: FontColor?.fields?.Text?.value || '#FFFFFF'
          }
        },
        defaultProps: {
          // The default props to change
          components: {
            CloseIcon: CloseIcon
          },
          iconMapping: {
            error: (
              <div className="WarningIconAndText">
                <WarningIcon />
                <RichText id="richtext-wrapper" field={AlertText} />
              </div>
            )
          }
        }
      }
    }
  });
  const [showAlert, setShowAlert] = useState(IsAlertVisible?.value || false);

  useEffect(() => {
    setHasAlert(showAlert);
  }, [showAlert]);

  return (
    showAlert && (
      <div className="AlertBoxContainer" role="region" aria-label="alertbanner">
        <ThemeProvider theme={theme}>
          <Alert
            id="alert-banner"
            onClose={() => {
              setShowAlert(false);
            }}
            severity="error">
            {/* <RichText id="richtext-wrapper" field={AlertText} /> */}
          </Alert>
        </ThemeProvider>
      </div>
    )
  );
};

export default withAlert(AlertBanner);
