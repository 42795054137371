import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AllstateButton from '../../common/Button';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { withTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Grid
} from '@mui/material';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import './mobilenav.scss';
import AboutContent from '../../AllStateAbout/AboutContent';
import Hamburger from '../../../assets/Hamburger.svg';
import LanguageIcon from '../../../assets/languageicon.svg';
import DownIcon from '../../../assets/dropdown-orange.svg';
import LeftIcon from '../../../assets/left-orange.svg';
import RightIcon from '../../../assets/right-arrow.svg';
import CallIcon from '../../../assets/callIcon.svg';
import AgencyIcon from '../../../assets/agency.svg';
import LocationIcon from '../../../assets/location-icon.svg';
import EmailIcon from '../../../assets/emailicon.svg';
import CloseIcon from '../../../assets/close-icon.svg';
import { withAlert } from '../../../contexts/alert';
import {
  Mobile_language,
  Mobile_province,
  search_keyword,
  About,
  searchText,
  findAnAgecy,
  menuBaseType,
  specialChars,
  LangEN,
  LangFR,
  geoUrl
} from '../../constants';
import { getBaseURL } from '../../../utils';

const drawerWidth = '100%';

const MobileNav = (props) => {
  const {
    logolink = null,
    topHeaderMenuLinks = [],
    primaryHeaderMenuLinks = [],
    logoImage,
    language,
    hasAlert,
    t,
    region
  } = props;
  const topHeaderData = topHeaderMenuLinks[0];
  const {
    allStateSiteslstNew,
    getQuote,
    geoLocationlst,
    languagelst,
    signIn,
    searchPage,
    Phonenumber = {},
    Email = {},
    EmailText = {}
  } = topHeaderData?.fields;
  const findAgencyData = primaryHeaderMenuLinks?.filter((menuLinks) => {
    return menuLinks?.displayname?.toLowerCase() === findAnAgecy;
  });
  const menuData = primaryHeaderMenuLinks?.filter((menuLinks) => {
    return menuLinks?.displayname?.toLowerCase() !== findAnAgecy;
  });

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuName, setMenuName] = React.useState(null);
  const [lang, setLang] = React.useState(null);
  const [selectLanguage, setSelectLanguage] = React.useState(languagelst[0]);
  const [selectProvince, setSelectProvince] = React.useState(geoLocationlst[0]);
  const [province, setProvince] = React.useState(null);
  const [menuType, setMenuType] = React.useState(menuBaseType);
  const [expanded, setExpanded] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const showOtherLinks =
    !menuName && !lang && !province && !(menuType === t(About));
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const resetMenu = () => {
    setMenuType(menuBaseType);
    setMenuName(null);
    setLang(null);
    setProvince(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => {
      if (prevState) {
        resetMenu();
      }
      return !prevState;
    });
  };

  const clickListener = (data, type = menuBaseType) => {
    setMenuType(type);
    if (!menuName && type === menuBaseType) {
      setMenuName(data);
    } else if (type === t(Mobile_language)) {
      setLang(data);
      setMenuName(null);
      setProvince(null);
    } else if (type === t(Mobile_province)) {
      setProvince(data);
      setMenuName(null);
      setLang(null);
    } else if (type === t(About)) {
      setProvince(null);
      setMenuName(null);
      setLang(null);
    }
  };

  const renderAboutUs = (data) => {
    const { ImageCaption } = data?.fields?.Icon?.fields;
    return (
      <>
        <ListItem
          className="pdt-0"
          onClick={() => clickListener(data, t(About))}>
          <>
            <ListItemText className="menu-item-mobile">
              <Typography className="menu-item-mobile-text ml-0">
                {ImageCaption?.value}
              </Typography>
            </ListItemText>
            {!menuName && (
              <img
                src={RightIcon}
                alt="back-icon"
                loading="lazy"
                height={16}
                width={16}
              />
            )}
          </>
        </ListItem>
      </>
    );
  };

  const renderLanguage = (data) => {
    return (
      <>
        <Divider />
        <ListItem onClick={() => clickListener(data, t(Mobile_language))}>
          <>
            <ListItemText className="menu-item-mobile">
              <img src={LanguageIcon} loading="lazy" width={19} height={18} />
              <Typography className="menu-item-mobile-text">
                {t(Mobile_language)}:{' '}
                {language === 'fr-CA'
                  ? data[1]?.displayName
                  : data[0]?.displayName}
              </Typography>
            </ListItemText>
            {!menuName && (
              <img
                src={RightIcon}
                alt="back-icon"
                loading="lazy"
                height={16}
                width={16}
              />
            )}
          </>
        </ListItem>
      </>
    );
  };

  const renderProvince = (data) => {
    return (
      <>
        <Divider />
        <ListItem onClick={() => clickListener(data, t(Mobile_province))}>
          <>
            <ListItemText className="menu-item-mobile">
              <img
                src={LocationIcon}
                alt="location-icon"
                loading="lazy"
                width={18}
                height={18}
              />
              <Typography className="menu-item-mobile-text">
                {/* {t(Mobile_province)}: {selectProvince?.fields?.Name?.value} */}
                {t(Mobile_province)}: {renderRegion(region, language)}
              </Typography>
            </ListItemText>
            {!menuName && (
              <img
                src={RightIcon}
                alt="back-icon"
                loading="lazy"
                height={16}
                width={16}
              />
            )}
          </>
        </ListItem>
      </>
    );
  };

  const renderOtherLinks = () => {
    return (
      <>
        <Divider />
        <ListItem>
          <ListItemText className="menu-item-mobile-contact">
            <img
              src={AgencyIcon}
              alt="find an agency icon"
              loading="lazy"
              width={20}
              height={20}
            />
            <Typography className="menu-item-mobile-text">
              <Link
                className="link-reset"
                field={findAgencyData[0]?.fields?.Link}
              />
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className="menu-item-mobile-contact">
            <img
              src={CallIcon}
              alt="call icon"
              loading="lazy"
              width={20}
              height={20}
            />
            <Typography className="menu-item-mobile-text">
              <a className="link-reset" href={`tel:${Phonenumber?.value}`}>
                <Text field={Phonenumber} />
              </a>
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className="menu-item-mobile-contact">
            <img src={EmailIcon} loading="lazy" width={21} height={22} />
            <Typography className="menu-item-mobile-text">
              <a className="link-reset" href={`mailto:${Email?.value}`}>
                <Text field={EmailText} />
              </a>
            </Typography>
          </ListItemText>
        </ListItem>
      </>
    );
  };

  const renderSubmenulevel2 = (submenuData) => {
    return submenuData?.map((submenuItem, i) => {
      const { fields } = submenuItem;
      const submenuValue = `submenu${i}`;
      return (
        <MenuItem className="submenu-mobile-links" key={submenuValue}>
          <Typography className="submenu-item-link">
            <Link field={fields?.Link} />
          </Typography>
        </MenuItem>
      );
    });
  };

  const selectList = (selectlist) => {
    return selectlist?.map((data, i) => {
      const selectText =
        menuType === t(Mobile_province)
          ? data?.fields?.Name?.value
          : data?.fields?.Link?.value?.text;
      return (
        <ListItem
          key={i}
          onClick={() => onSelectItem(data, menuType, data?.fields)}>
          <>
            <ListItemText
              className="list-item-text-mobile"
              primary={selectText}
            />
          </>
        </ListItem>
      );
    });
  };

  const renderSubmenu = (submenuList) => {
    if (menuType === menuBaseType) {
      return submenuList?.map((data, i) => {
        const isSubmenuAvailable = data?.fields?.submenuSection?.length > 0;
        const panelValue = `panel${i}`;
        const submenuSection = data?.fields?.submenuSection || null;
        return (
          <ListItem key={i} onClick={() => clickListener(data)}>
            {!isSubmenuAvailable && (
              <>
                <ListItemText className="list-item-text-mobile">
                  {data?.fields?.Link?.value?.href !== '' ? (
                    <Link field={data?.fields?.Link} />
                  ) : (
                    <Text field={data?.fields?.menuText} />
                  )}
                </ListItemText>
                {!menuName && (
                  <img
                    src={RightIcon}
                    alt="right-icon"
                    loading="lazy"
                    height={16}
                    width={16}
                  />
                )}
              </>
            )}
            {isSubmenuAvailable && (
              <>
                <Accordion
                  className="mobile-menu-accordion"
                  expanded={expanded === panelValue}
                  onChange={handleChange(panelValue)}>
                  <AccordionSummary
                    className="menu-accordion-summary"
                    expandIcon={
                      <img
                        src={DownIcon}
                        alt="dropdown-icon"
                        loading="lazy"
                        height={15}
                        width={16}
                      />
                    }
                    aria-controls="panel1d-content"
                    id="panel1d-header">
                    <Typography className="menu-dropdown">
                      <Text field={data?.fields?.menuText} />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="mobile-accordian-details">
                    {submenuSection && renderSubmenulevel2(submenuSection)}
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </ListItem>
        );
      });
    } else if (menuType === t(About)) {
      return <AboutContent {...allStateSiteslstNew} />;
    }
    return selectList(submenuList);
  };

  const renderLinks = (menuName) => {
    if (menuName) {
      return menuName?.fields?.menuSection;
    } else if (lang) {
      return lang;
    } else if (province) {
      return province;
    } else {
      return menuData;
    }
  };

  const onBack = () => {
    resetMenu();
  };

  const fetchData = async (geoLocation) => {
    const baseURL = getBaseURL();
    try {
      const data = await fetch(`${baseURL}${geoUrl}?state=${geoLocation}`);
      const json = await data.json();
      if (json.FetchData) {
        window.open(`${window.location.href}`, '_self');
        // console.log('>>>>>>>>>>>>GEO', json);
      }
    } catch {
      console.log('error, something went wrong');
    }
  };

  const onSelectItem = (selectData, type, langlist) => {
    if (type === t(Mobile_language)) {
      setSelectLanguage(selectData);
      if (
        selectData.displayName === 'French' ||
        selectData.displayName === 'FR' ||
        selectData.displayName === 'English' ||
        selectData.displayName === 'EN' ||
        selectData.displayName === 'Français' ||
        selectData.displayName === 'Anglais'
      ) {
        const value =
          selectData.displayName === 'French' ||
          selectData.displayName === 'FR' ||
          selectData.displayName === 'Français'
            ? LangFR
            : LangEN;
        if (value !== language.toLowerCase()) {
          if (value.toLowerCase() === 'en-ca') {
            const currentURL = window.location.href?.toLowerCase();
            const frPresent = currentURL?.includes('fr-ca');
            if (frPresent) {
              const Present = currentURL?.includes('fr-ca/');
              if (Present) {
                const newURL = currentURL?.replace('fr-ca/', '');
                document.cookie = `allstateca#lang=${value};path=/`;
                sessionStorage.setItem('selectLang', value);
                window.open(newURL, '_self');
              } else {
                const newURL = currentURL?.replace('fr-ca', '');
                document.cookie = `allstateca#lang=${value};path=/`;
                sessionStorage.setItem('selectLang', value);
                window.open(newURL, '_self');
              }
            } else {
              document.cookie = `allstateca#lang=${value};path=/`;
              sessionStorage.setItem('selectLang', value);
              window.open(window.location.href, '_self');
            }
          }
          if (value.toLowerCase() === 'fr-ca') {
            const newURL = window.location.href?.replace(
              window.location.origin,
              ''
            );
            window.open(
              `${window.location.origin}/${value.toLowerCase()}${newURL}`,
              '_self'
            );
          }
        }
        // document.cookie = `allstateca#lang=${value};path=/`;
        // sessionStorage.setItem('selectLang', value);
        // window.open(window.location.href, '_self');
        // const engPresent = window.location.href?.includes('en-ca');
        // const frPresent = window.location.href?.includes('fr-ca');
        // if (value !== language.toLowerCase() && engPresent) {
        //   const newURL = window.location.href?.replace('en-ca', 'fr-ca');
        //   window.open(`${newURL}`, '_self');
        // } else if (value !== language.toLowerCase() && frPresent) {
        //   const newURL = window.location.href?.replace('fr-ca', 'en-ca');
        //   window.open(`${newURL}`, '_self');
        // } else if (
        //   window.location.origin !== window.location.href &&
        //   !engPresent &&
        //   !frPresent
        // ) {
        //   const newURL = window.location.href?.replace(
        //     window.location.origin,
        //     ''
        //   );
        //   window.open(`${window.location.origin}/${value}${newURL}`, '_self');
        // }
      } else {
        window.open(langlist?.Link?.value?.href, '_blank');
      }
    } else if (type === t(Mobile_province)) {
      setSelectProvince(selectData);
      // console.log('>>>>>>>>>>>>GEO', selectData.name);
      fetchData(returnlocation(selectData.name));
    }
    onBack();
  };

  const returnlocation = (geoLocation) => {
    switch (geoLocation) {
      case 'Alberta':
        return 'AB';
      case 'Ontario':
        return 'ON';
      case 'Quebec':
        return 'QC';
      case 'New Brunswick':
        return 'NB';
      case 'Nova Scotia':
        return 'NS';
      default:
        return 'AB';
    }
  };

  const renderRegion = (region, language) => {
    if (language === 'fr-CA') {
      switch (region) {
        case 'QC':
          return 'Québec';
        case 'ON':
          return 'Ontario';
        case 'NB':
          return 'Nouveau-Brunswick';
        case 'NS':
          return 'Nouvelle-É cosse';
        default:
          return 'Alberta';
      }
    } else {
      switch (region) {
        case 'AB':
          return 'Alberta';
        case 'ON':
          return 'Ontario';
        case 'QC':
          return 'Quebec';
        case 'NB':
          return 'New Brunswick';
        case 'NS':
          return 'Nova Scotia';
        default:
          return 'Alberta';
      }
    }
  };
  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    e.key === 'Enter' && validateInput(e);
  };

  const container = undefined;
  // window !== undefined ? () => window().document.body : undefined;
  const list = () => {
    let arr = renderLinks(menuName);
    return (
      <div
        className={''}
        role="presentation"
        // onKeyDown={setMobileOpen(false)}
      >
        <List>{renderSubmenu(arr)}</List>
      </div>
    );
  };

  const handelredirect = () => {
    window.open(
      `${window.location.origin}${searchPage?.value?.href}?q=${searchValue}`,
      '_self'
    );
  };
  const searchButtonText = { value: { text: searchPage?.value?.text } };
  const validateInput = (e) => {
    if (!specialChars.test(searchValue)) {
      e.preventDefault();
      setError(true);
    } else if (searchValue?.length < 3) {
      e.preventDefault();
      setError(true);
    } else {
      setError(false);
      sessionStorage.setItem('searchQuery', searchValue);
      handelredirect();
    }
  };

  return (
    <Box className="mobile-menu-bar" sx={{ display: 'flex' }}>
      <AppBar
        className="appbar"
        component="nav"
        position="fixed"
        data-test-id="mobile-nav">
        <Toolbar>
          <Grid item xs={9} md={6} className="appbar-grid">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className="hamburger-button"
              onClick={handleDrawerToggle}>
              <img
                src={mobileOpen ? CloseIcon : Hamburger}
                alt="menu-expand-icon"
                loading="lazy"
                height={mobileOpen ? 14 : 33}
                width={mobileOpen ? 15 : 32}
              />
            </IconButton>
            <Link className="logo-link" field={logolink?.value}>
              <img
                className="logo-mobile"
                src={logoImage?.value?.src}
                alt="Allstate-logo"
                loading="lazy"
              />
            </Link>
            <AllstateButton
              variant="primary"
              size="small"
              content={getQuote}
              component="button"
              buttonWrapperClass="button-getquote"
            />
          </Grid>
          <Grid item xs={3} md={6}>
            <Typography variant="h6" className="sign-in-button">
              <Link field={signIn} className="sign-in-button-anchor" />
              {/* {signIn?.value?.text} */}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          className={hasAlert && 'hasAlert'}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          disableScrollLock={false}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}>
          <Box className="search-wrapper-mobile">
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                className="search-input"
                id="search-input"
                value={searchValue}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  onSearchChange(e);
                }}
                error={error}
                endAdornment={
                  <AllstateButton
                    onClick={(e) => validateInput(e)}
                    variant="search"
                    className="search-input-button"
                    content={searchButtonText}
                  />
                }
                inputProps={{
                  'aria-label': t(searchText),
                  placeholder: t(search_keyword)
                }}
              />
            </FormControl>
          </Box>
          {!showOtherLinks && (
            <ListItem onClick={() => onBack()}>
              <img
                src={LeftIcon}
                alt="back-icon"
                loading="lazy"
                height={16}
                width={15}
              />
              <ListItemText
                className="menu-back-text"
                primary={menuName?.fields?.menuText?.value || menuType}
              />
            </ListItem>
          )}
          <Divider />
          {list()}
          {showOtherLinks && renderAboutUs(allStateSiteslstNew)}
          {showOtherLinks &&
            languagelst?.length > 0 &&
            language !== '' &&
            language !== undefined &&
            renderLanguage(languagelst)}
          {showOtherLinks &&
            geoLocationlst?.length > 0 &&
            renderProvince(geoLocationlst)}
          {showOtherLinks && renderOtherLinks()}
        </Drawer>
      </Box>
    </Box>
  );
};

export default withTranslation()(withAlert(MobileNav));
